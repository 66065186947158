import React, { FC, useMemo, useState, useEffect } from 'react';
import cl from './style.module.css';
import Filters from './components/Filters';
import ReportWindow from 'localentities/report/components/ReportWindow';
import Text from 'shared/ui/Text';
import { Button, Divider, Drawer, Dropdown, Input, MenuProps, Modal, Popconfirm } from 'antd';
import Settings from './components/Settings';
import Display from './components/Display';
import {
    DeleteOutlined,
    FolderOpenOutlined,
    FolderOutlined,
    SearchOutlined,
    DownloadOutlined,
} from '@ant-design/icons';
import {
    useAddTemplateMutation,
    useDeleteTemplateMutation,
    useGetTemplatesQuery, useLazyGetTemplateQuery,
} from 'localentities/filter/model/endpoints';
import { useGetReportsQuery } from 'localentities/report/model/endpoints';
import { useGetSettingsQuery } from 'localentities/settings/model/endpoints';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { ISaveTemplate } from 'localentities/filter/types';
import Spinner from 'shared/ui/Spinner';
import { useScreenWidth } from 'shared/hooks/useScreenWidth';
import CitiesAlphabet from 'localentities/filter/components/CitiesAlphabet';
import CitiesDistrict from 'localentities/filter/components/CitiesDistrict';
import FuncGroup from 'localentities/filter/components/FuncGroup';
import SettingsIcon from 'shared/assets/icons/settings.svg';
import { ReactSVG } from 'react-svg';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import {
    setIndex, setCountMonths, setIsApplyDistrictCoefficient, setIncludeTax,
} from 'shared/store/reducers/commonSlice';

import { isNil } from 'lodash'; // Импортируем экшен

// import { useGetExcelMutation } from 'localentities/filter/model/endpoints';

const Report: FC = () => {
    const width = useScreenWidth();
    const dispatch = useAppDispatch();
    const { reportNumber } = useParams();
    const { data, isLoading, isSuccess, isError } = useGetReportsQuery();

    const {
        periods,
        cities,
        func_groups,
        levels,
        comp_groups,
        filtersChain,
        isApplyDistrictCoefficient,
        includeTax,
        index,
        currency
    } = useAppSelector(state => state.common);
    const [isOpenCities, setIsOpenCities] = useState<boolean>(false);
    const [isOpenFuncGroup, setIsOpenFuncGroup] = useState<boolean>(false);
    const [isOpenSettings, setIsOpenSettings] = useState<boolean>(false);
    const [isOpenFilters, setIsOpenFilters] = useState<boolean>(width > 768);
    const [isOpenSaveModal, setIsOpenSaveModal] = useState<boolean>(false);
    const [templateName, setTemplateName] = useState<string>('');
    const [selectedSettings, setSelectedSettings] = useState<'display' | 'settings'>('settings');
    const [selectedCitiesSort, setSelectedCitiesSort] = useState<'alphabet' | 'district'>('alphabet');
    const [selectedFuncGroupSort, setIsOpenFuncGroupSort] = useState<'' | ''>('');
    const [searchValueAlphabet, setSearchValueAlphabet] = useState<string>('');
    const [searchValueDistrict, setSearchValueDistrict] = useState<string>('');
    const [addTemplate, { isLoading: isLoadingAddTemplate }] = useAddTemplateMutation();
    const { data: templates, isFetching: isLoadingTemplates } = useGetTemplatesQuery(reportNumber ?? '0');
    const [deleteTemplate, { isLoading: isLoadingDelete }] = useDeleteTemplateMutation();
    const [getTemplate, { data: template, isFetching: isLoadingTemplate }] = useLazyGetTemplateQuery();
    const { data: settings } = useGetSettingsQuery(reportNumber ?? '0');
    const [searchValueFuncGroup, setSearchValueFuncGroup] = useState<string>('');

    const [error, setError] = useState<string | null>(null);
    const [reportList, setReportList] = useState<any[]>([]);

    useEffect(() => {
        // Проверяем, что данные для отчетов загрузились, и обновляем состояние
        if (isSuccess && data) {
            const reports = Array.isArray(data) ? data : data.reports;
            setReportList(reports);  // Сохраняем список отчетов в состоянии
        } else if (isError) {
            console.error("Ошибка загрузки отчетов");
        }
    }, [data, isSuccess, isError]);

    useEffect(() => {
        if(settings) {
            if(settings.settings?.indexed) {
                dispatch(setIndex(settings.settings?.absolute_indexation ? 'abs' : 'year'));
            } else {
                dispatch(setIndex(undefined));
            }
            if(!isNil(settings.settings?.months_since_review)) {
                dispatch(setCountMonths(settings.settings?.months_since_review))
            }
            if(!isNil(settings.settings?.regional_coefficient_enabled)) {
                dispatch(setIsApplyDistrictCoefficient(settings.settings?.regional_coefficient_enabled ?? false))
            }
            if(!isNil(settings.settings?.tax_included_gross)) {
                dispatch(setIncludeTax(settings.settings?.tax_included_gross ?? false))
            }
        }
    }, [settings, currency]);

    const API_URL = process.env.REACT_APP_API_URL;
    const handleExcelDownload = async (type: string) => {
    try {
        // const API_URL = 'http://localhost:80/api';
        // https://salaryguide.ru/api/report/subreport/download/excel/?type=${type}
        const response = await fetch(`${API_URL}report/subreport/download/excel/?type=${type}`, {
            method: 'GET',
        });

        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }

        // Извлекаем заголовок Content-Disposition
        const contentDisposition = response.headers.get('Content-Disposition');
        console.log(contentDisposition, "Content-Disposition header");

        // Стандартное имя файла по умолчанию
        let filename = 'Отчет.xlsx';
        if (contentDisposition) {
            const matches = /filename\*?=(.*)/.exec(contentDisposition);
            if (matches && matches[1]) {
                const encodedFilename = matches[1].replace(/UTF-8''/i, ''); // Удаляет 'UTF-8'' перед именем
                filename = decodeURIComponent(encodedFilename);
            }
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url); // Clean up URL object
    } catch (error) {
        console.error('Error downloading Excel file:', error);
    }
};

    const downloadMenu: MenuProps['items'] = [
        {
            key: 1,
            label: <Text className={cl.downloadMenuElem} variant="xxs" color="gray-9">Выбранные должности</Text>,
            onClick: () => handleExcelDownload('selected') // Передаем параметр типа
        },
        {
            key: 2,
            label: <Text className={cl.downloadMenuElem} variant="xxs" color="gray-9">Полный отчет</Text>,
            onClick: () => handleExcelDownload('full') // Передаем параметр типа
        }
    ];


    const getOpenMenuLabel = (text: string, Icon: React.ReactNode, templateId: number) => (
        <div
            className={cl.openMenuElemWrapper}
            role="button"
            tabIndex={0}
            onClick={() => getTemplate({
                reportNumber: reportNumber ?? '0',
                templateId
            })}
        >
            <Text variant="xxs" color="gray-9">{text}</Text>
            <Popconfirm
                zIndex={1100}
                title="Вы уверены, что хотите удалить?"
                okText="Да"
                cancelText="Нет"
                onConfirm={() => !isLoadingDelete && deleteTemplate({
                    reportNumber: reportNumber ?? '0',
                    templateId
                })}
                placement="bottom"
            >
                {Icon}
            </Popconfirm>
        </div>
    );

    const openMenu: MenuProps['items'] = useMemo(() => !templates?.length
        ?   [{
                key: 0,
                label: <Text variant="xxs" color="gray-9">Шаблонов нет</Text>
            }]
        :   templates?.map(t => ({
                key: t.id,
                label: getOpenMenuLabel(t.name, <DeleteOutlined onClick={e => e.stopPropagation()} />, t.id)
    })), [templates]);



    const handleSave = () => {
        if(!!templateName && templateName.length <= 60) {
            const filters: ISaveTemplate = { filter: [], chain: filtersChain };
            filters.filter.push({
                filterName: 'periods',
                keys: periods ?? ''
            });
            filters.filter.push({
                filterName: 'subjects',
                keys: cities ?? ''
            });
            filters.filter.push({
                filterName: 'func_groups',
                keys: func_groups ?? ''
            });
            filters.filter.push({
                filterName: 'levels',
                keys: levels ?? ''
            });
            filters.filter.push({
                filterName: 'comp_groups',
                keys: comp_groups ?? ''
            });
            addTemplate({
                reportNumber: reportNumber ?? '0',
                name: templateName,
                filters
            })
                .unwrap()
                .then(() => {
                    setIsOpenSaveModal(false);
                    setTemplateName('');
                    setError(null); // Очищаем ошибку при успешном сохранении
                })
                .catch((error) => {
                    setError(error.message); // Устанавливаем ошибку при возникновении
                });
        }
    };


    const handleCloseModal = () => {
        setIsOpenSaveModal(false);
        setError(null); // Очистка ошибки
        // Очистите другие данные, если это необходимо
    };

    const getTitle = (
        selected: 'settings' | 'district' | 'display' | 'alphabet' | '',
        nameFirst: string,
        nameSecond: string
    ) => (
        <div className={cl.titleWrapper}>
            {selected === 'alphabet' || selected === 'district' ? (
            <Input
                value={selected === 'alphabet' ? searchValueAlphabet : searchValueDistrict}
                onChange={e => selected === 'alphabet' ? setSearchValueAlphabet(e.target.value) : setSearchValueDistrict(e.target.value)}
                placeholder="Название города"
                suffix={<SearchOutlined />}
                allowClear
            />
        ) : null}
        {selected === '' ? (
            <Input
                value={searchValueFuncGroup}
                onChange={e => selected === '' ? setSearchValueFuncGroup(e.target.value) : setSearchValueFuncGroup(e.target.value)}
                placeholder="Область специализации или код"
                suffix={<SearchOutlined />}
                allowClear
            />
        ) : null}
            <div className={cl.titleWrapperText}>
                <Text
                    className={selected === 'settings' || selected === 'alphabet'
                        ? [cl.title, cl.activeTitle].join(' ')
                        : cl.title
                    }
                    variant="s"
                    color="primary"
                    role="button"
                    tabIndex={0}
                    onClick={() => selected === 'display'
                        ? setSelectedSettings('settings')
                        : selected === 'district' && setSelectedCitiesSort('alphabet')
                    }
                >
                    {nameFirst}
                </Text>
                <Text
                    className={selected === 'display' || selected === 'district'
                        ? [cl.title, cl.activeTitle].join(' ')
                        : cl.title
                    }
                    variant="s"
                    color="primary"
                    role="button"
                    tabIndex={0}
                    onClick={() => selected === 'settings'
                        ? setSelectedSettings('display')
                        : selected === 'alphabet' && setSelectedCitiesSort('district')
                    }
                >
                    {nameSecond}
                </Text>

            </div>
        </div>
    );

    return (
        <div className={cl.wrapper}>
            <div className={cl.content}>
            <>
                    {/* Проверяем, загружен ли список отчетов */}
                    {isLoading ? (
                        <Spinner size="l" />
                    ) : reportList.length > 0 ? (
                        <Filters
                            isOpenFilters={isOpenFilters}
                            setIsOpenFilters={setIsOpenFilters}
                            isOpenCities={isOpenCities}
                            setIsOpenCities={setIsOpenCities}
                            isOpenFuncGroup={isOpenFuncGroup}
                            setIsOpenFuncGroup={setIsOpenFuncGroup}
                            template={template}
                            triggerTemplate={isLoadingTemplate}
                            allReports={reportList}
                        />
                    ) : (
                        <p>Нет доступных отчетов</p>  // В случае отсутствия отчетов выводим сообщение
                    )}
                </>


                <div className={[cl.reportWindowWrapper, 'report'].join(' ')}>
                    <div className={cl.pageMenu}>
                        <Dropdown menu={{ items: downloadMenu }} placement="bottom">
                            <div className={cl.menuElem}>
                                <DownloadOutlined />
                                {width > 768 && <Text className={cl.linkText} variant="s" color="gray-9">Скачать</Text>}
                            </div>
                        </Dropdown>
                        <div
                            className={cl.menuElem}
                            onClick={() => setIsOpenSaveModal(true)}
                            onKeyDown={e => e.key === 'Enter' && setIsOpenSaveModal(true)}
                            role="button"
                            tabIndex={0}
                        >
                            <FolderOutlined />
                            <Text className={cl.linkText} variant="s" color="gray-9">Сохранить</Text>
                        </div>
                        {isLoadingTemplates
                            ?   <Spinner size="s" />
                            :   <Dropdown menu={{ items: openMenu }} placement="bottom">
                                    <div className={cl.menuElem}>
                                        <FolderOpenOutlined />
                                        <Text className={cl.linkText} variant="s" color="gray-9">Открыть</Text>
                                    </div>
                                </Dropdown>
                        }
                        <Button
                            className={width > 480
                                ? [cl.menuElem, cl.settingsButton].join(' ')
                                : [cl.menuElem, cl.settingsButton, cl.settingsButtonSmall].join(' ')
                            }
                            onClick={() => setIsOpenSettings(true)}
                            onKeyDown={e => e.key === 'Enter' && setIsOpenSettings(true)}
                        >
                            <ReactSVG src={SettingsIcon} />
                            {width > 480 &&
                                <Text className={cl.linkText} variant="s" color="gray-9">Настроить</Text>
                            }
                        </Button>
                    </div>
                    <ReportWindow isOpenFilters={isOpenFilters}  allReports={reportList} />
                    <div className={isOpenFilters ? cl.settings : [cl.settings, cl.fullSettings].join(' ')} style={{background: "#F3F6F9", rowGap: '1px', columnGap: '1px', padding: '5px 0',  transition: 'all .3s ease-in-out'}}>
                        <Text variant="xxs" color="gray-8" style={{background: "var(--color-gray-5)", padding: '5px 20px'}}>
                            Районные коэффициенты: {isApplyDistrictCoefficient ? 'включены' : 'исключены'}
                        </Text>
                        <Text variant="xxs" color="gray-8" style={{background: "var(--color-gray-5)", padding: '5px 20px'}}>
                            Индексация: {index ? 'включена' : 'выключена'}
                        </Text>
                        <Text variant="xxs" color="gray-8" style={{background: "var(--color-gray-5)", padding: '5px 20px'}}>
                            Налог: {includeTax ? 'исключен': `включен: ${settings?.tax_rates[0].tax_rate}%`}
                        </Text>
                        <Text variant="xxs" color="gray-8" style={{background: "var(--color-gray-5)", display: "flex", flexGrow: 1, padding: '5px 20px'}}>
                            Валюта: {currency}
                        </Text>
                    </div>
                </div>
            </div>
            <Drawer
                title={getTitle(selectedSettings, 'Настройки отчета', 'Параметры отображения')}
                placement="right"
                size={width > 768 ? 'large' : 'default'}
                onClose={() => setIsOpenSettings(false)}
                open={isOpenSettings}
                getContainer={false}
                closable={false}
            >
                <div className={cl.drawerWrapper}>
                    {selectedSettings === 'settings' ? <Settings /> : <Display />}
                    {/* <div className={cl.drawerButtons}>
                        <Button className={cl.applyButton} onClick={() => setIsOpenSettings(false)}>
                            Отменить
                        </Button>
                        <Button className={cl.applyButton} type="primary" onClick={() => setIsOpenSettings(false)}>
                            Применить
                        </Button>
                    </div> */}
                </div>
            </Drawer>
            <Drawer
                title={getTitle(selectedCitiesSort, 'По алфавиту', 'По регионам и областям')}
                placement="right"
                size={width > 768 ? 'large' : 'default'}
                onClose={() => setIsOpenCities(false)}
                open={isOpenCities}
                getContainer={false}
                closable={false}
            >
                <div className={cl.drawerWrapper}>
                    {selectedCitiesSort === 'alphabet'
                        ? <CitiesAlphabet searchValue={searchValueAlphabet} />
                        : <CitiesDistrict searchValue={searchValueDistrict} />
                    }
                </div>
            </Drawer>
            <Drawer
                title={getTitle(selectedFuncGroupSort, '', '')}
                placement="right"
                size={width > 768 ? 'large' : 'default'}
                onClose={() => setIsOpenFuncGroup(false)}
                open={isOpenFuncGroup}
                getContainer={false}
                closable={false}
            >
                <div className={cl.drawerWrapper}>
                    {selectedFuncGroupSort === '' ? <FuncGroup searchValue={searchValueFuncGroup}/> : <Display />}
                </div>
            </Drawer>
            <Modal
                title="Сохранить как шаблон"
                open={isOpenSaveModal}
                onCancel={handleCloseModal}
                footer={null}
                centered
            >
                {error && (
                <div className={cl.error}>
                    <Text variant="xxs" color="red">{error}</Text>
                </div>
            )}
                <Divider className={cl.separator} />
                <Input
                    className={cl.input}
                    value={templateName}
                    onChange={e => e.target.value.length <= 60 && setTemplateName(e.target.value)}
                    onKeyDown={e => e.key === 'Enter' && templateName && !isLoadingAddTemplate && handleSave()}
                />
                <Divider className={cl.separator} />
                <div className={cl.buttons}>
                    <Button onClick={() => setIsOpenSaveModal(false)}>Отмена</Button>
                    <Button
                        type="primary"
                        onClick={handleSave}
                        disabled={!templateName || isLoadingAddTemplate}
                    >
                        Сохранить
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default Report;

