import React, {FC} from 'react';
import cl from './style.module.css';
import LoginImage from 'shared/assets/images/login.png';
import Text from 'shared/ui/Text';
import {Button, Divider, Form, Input} from 'antd';
import LogoIcon from 'shared/assets/icons/logo.svg';
import {ReactSVG} from 'react-svg';
import {useLoginMutation} from 'localentities/user/model/enpoints';
import {useFormik} from 'formik';
import {ILogin} from 'localentities/user/types';
import {validationLoginSchema} from 'localentities/user/validate';
import {useAppDispatch} from 'shared/hooks/useAppDispatch';
import {setIsAuth, setPoliciesActive} from 'shared/store/reducers/commonSlice';
import {useScreenWidth} from 'shared/hooks/useScreenWidth';
import {useNavigate} from 'react-router-dom';

const Login: FC = () => {
    const width = useScreenWidth();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [login, {isLoading: isLoadingLogin}] = useLoginMutation();

    const {handleSubmit, values, errors, setErrors, setFieldValue} = useFormik<ILogin>({
        initialValues: {
            username: '',
            password: ''
        },
        onSubmit: data => {
            login({ ...data })
                .unwrap()
                .then((response: any) => {  // приведение к any для доступа к policies_active
                    // Сохраняем авторизацию и флаг policies_active в глобальное состояние
                    dispatch(setIsAuth(true));
                    dispatch(setPoliciesActive(response.policies_active));
                    // Если policies_active выключена, делаем редирект на /report/
                    if (!response.policies_active) {
                        navigate('/report');
                    }
                })
                .catch(err => {
                    console.error('Ошибка логина:', err);
                });
        },
        validationSchema: validationLoginSchema,
        validateOnBlur: false,
        validateOnChange: false
    });

    const handleChange = (field: string, value: string) => {
        setErrors({...errors, [field]: ''});
        setFieldValue(field, value);
    };

    return (
        <div className={cl.wrapper}>
            <Text className={cl.title} variant={width > 480 ? 'header' : 'header-small'} color="brand-1">
                Приветствуем вас на портале зарплат АМТ Консалт
            </Text>
            <div className={cl.content}>
                <Form
                    className={cl.form}
                    style={{maxWidth: width > 480 ? 295 : 'none'}}
                >
                    <div className={cl.field}>
                        {!!errors.username &&
                            <Text variant="s" color="red">{errors.username}</Text>
                        }
                        <Input
                            className={cl.input}
                            value={values.username}
                            onChange={e => handleChange('username', e.target.value)}
                            status={errors.username ? 'error' : ''}
                            maxLength={150}
                        />
                        <Text variant="s" color="gray-9">Логин</Text>
                    </div>

                    <div className={cl.field}>
                        {!!errors.password &&
                            <Text variant="s" color="red">{errors.password}</Text>
                        }
                        <Input.Password
                            className={cl.input}
                            value={values.password}
                            onChange={e => handleChange('password', e.target.value)}
                            status={errors.password ? 'error' : ''}
                            maxLength={255}
                        />
                        <Text variant="s" color="gray-9">Пароль</Text>
                    </div>

                    <Button
                        className={cl.button}
                        type="primary"
                        htmlType="submit"
                        size="large"
                        onClick={() => handleSubmit()}
                        loading={isLoadingLogin}
                        disabled={isLoadingLogin}
                    >
                        Войти
                    </Button>
                </Form>
                {width > 950 &&
                    <img className={cl.image} src={LoginImage} alt="Картинка"/>
                }
            </div>
            {width > 950 &&
                <ReactSVG className={cl.logo} src={LogoIcon}/>
            }

            <Divider/>

            <div className={cl.bottom}>
                <Text variant="xs" color="gray-7">
                    Свяжитесь с нами для восстановления или получения доступа и
                    по всем вопросам, связанным с работой системы:
                </Text>
                <a href="tel:+74957885502">
                    <Text variant="xs" color="gray-7">
                        +7 496 788 5502
                    </Text>
                </a>
                <a href="mailto:survey@amtconsult.ru">
                    <Text variant="xs" color="gray-7">
                        survey@amtconsult.ru
                    </Text>
                </a>
                <a href="https://www.salarysurvey.ru" target="_blank" rel="noreferrer noopener">
                    <Text variant="xs" color="gray-7">
                        www.salarysurvey.ru
                    </Text>
                </a>
            </div>
        </div>
    );
};

export default Login;
