import React, { FC, useEffect, useState } from 'react';
import cl from './style.module.css';
import Text from 'shared/ui/Text';
import { Checkbox, Input, Radio, Select } from 'antd';
import { useParams } from 'react-router-dom';
import { useGetFiltersQuery } from '../../../../localentities/filter/model/endpoints';
// Импортируем хуки напрямую
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import {
    setDisplayObjectField,
    setIsShowCompanyTable,
    setIsShowEmployeeTable,
    setAllDisplayObjectValues,
    setIsShowAverage,
    setIsShowFuncGroupDescription,
    setIsShowLevelDescription,
    setIsShowCompanyCount,
    setQual,
} from 'shared/store/reducers/commonSlice';
import { useChangeSettingsMutation } from 'localentities/settings/model/endpoints';
import { RootState } from 'shared/store';

const Display: FC = () => {
    const dispatch = useAppDispatch();
    const { reportNumber } = useParams();
    const { data: filters } = useGetFiltersQuery(reportNumber ?? '0');
    const {
        percentsInTable,
        columnHeadersInTable,
        displayObject,
        isShowCompanyTable,
        isShowEmployeeTable,
        isShowFuncGroupDescription,
        isShowLevelDescription,
        qual,
    } = useAppSelector((state: RootState) => state.common);

    // Состояния для уровней квалификации (только для k3, k2, k1)
    const [isHighQualificationShown, setIsHighQualificationShown] = useState<boolean>(false);  // k3 – Высокая
    const [isStandardQualificationShown, setIsStandardQualificationShown] = useState<boolean>(false); // k2 – Стандартная
    const [isLowQualificationShown, setIsLowQualificationShown] = useState<boolean>(false); // k1 – Начальная

    // Остальные состояния
    const [isAllSelected, setIsAllSelected] = useState<boolean>(true);
    let leftColumnItems: JSX.Element[] = [];
    let rightColumnItems: JSX.Element[] = [];
    let thirdColumnItems: JSX.Element[] = [];

    const [isCompanyDataShown, setIsCompanyDataShown] = useState<boolean>(true);

    const [changeSettings, { isLoading: isLoadingChangeSettings }] = useChangeSettingsMutation();

    // Логика для выбранных квалификаций:
    // Если ни одна галочка не выбрана или выбраны все – newQual = "all" (показываются все таблицы).
    // Иначе, newQual = "ka," + выбранные (то есть таблица ka всегда показывается, а затем только выбранные).
    const updateQualState = () => {
        let newQual: string;
        const selected: string[] = [];
        if (isHighQualificationShown) selected.push("k3");
        if (isStandardQualificationShown) selected.push("k2");
        if (isLowQualificationShown) selected.push("k1");
        newQual = selected.join(",");

        dispatch(setQual(newQual));
        if (reportNumber && !isLoadingChangeSettings) {
            changeSettings({
                reportNumber,
                field: 'selected_quals',
                value: newQual.split(","),
            });
        }
    };

    useEffect(() => {
        updateQualState();
    }, [isHighQualificationShown, isStandardQualificationShown, isLowQualificationShown]);

    const handleSelectAll = (isChecked: boolean) => {
        const updatedDisplayObject = displayObject.map((item: { key: string; value: boolean }) => ({
            ...item,
            value: columnHeadersInTable.includes(item.key) ? isChecked : item.value
        }));
        dispatch(setAllDisplayObjectValues(updatedDisplayObject));
    };

    const shouldMoveToRightColumn: boolean = columnHeadersInTable.length >= 7;
    if (shouldMoveToRightColumn) {
        for (let i = 0; i < columnHeadersInTable.length; i++) {
            const ch: string = columnHeadersInTable[i];
            const checkbox = (
                <Checkbox
                    key={ch}
                    checked={displayObject.find((d: { key: string; value: boolean }) => d.key === ch)?.value ?? true}
                    onChange={() => dispatch(setDisplayObjectField(ch))}
                >
                    <Text variant="xxs" color="gray-8">{ch}</Text>
                </Checkbox>
            );
            if (i < 7) {
                leftColumnItems.push(checkbox);
            } else if (i < 14) {
                rightColumnItems.push(checkbox);
            } else {
                thirdColumnItems.push(checkbox);
            }
        }
    }

    const takecountofcompany = percentsInTable.filter((p: string) =>
        p !== '90%' &&
        p !== '75%' &&
        p !== '50%' &&
        p !== '25%' &&
        p !== '10%' &&
        p !== 'Среднее'
    );
    const updatedPercentsInTable = percentsInTable.filter((p: string) =>
        p !== 'Кол-во сотрудников' &&
        p !== 'Подчинённых сотр.' &&
        p !== 'Кол-во компаний' &&
        p !== '% от Доход в год'
    );
    const nameMappingcomp: { [key: string]: string } = {
        'Кол-во компаний': 'Кол-во компаний',
        'Подчинённых сотр.': 'Количество подчиненных',
        'Кол-во сотрудников': 'Кол-во сотрудников',
    };
    const nameMapping: { [key: string]: string } = {
        '90%': '90% (9-й децинтиль)',
        '75%': '75% (3-й квартиль)',
        '50%': '50% (медиана)',
        '25%': '25% (1-й квартиль)',
        '10%': '10% (1-й децинтиль)',
        'Среднее': 'Среднее арифметическое',
    };
    const handleCheckboxChange = (oldName: string) => {
        dispatch(setDisplayObjectField(oldName));
    };

    return (
        <div className={cl.wrapper}>
            <div className={cl.block}>
                <div className={cl.column}>
                    <Text className={cl.title} variant="s" color="gray-8" bold>Показатели</Text>
                    {updatedPercentsInTable.map((oldName: string) => {
                        const newName = nameMapping[oldName] || oldName;
                        const isChecked = displayObject.find((d: { key: string; value: boolean }) => d.key === oldName)?.value ?? false;
                        return (
                            <Checkbox
                                key={oldName}
                                checked={isChecked}
                                onChange={() => handleCheckboxChange(oldName)}
                            >
                                <Text variant="xxs" color="gray-8">{newName}</Text>
                            </Checkbox>
                        );
                    })}
                </div>
                <div className={cl.column}>
                    <Text className={cl.title} variant="s" color="gray-8" bold>Отображать</Text>
                    <Checkbox
                        checked={isCompanyDataShown}
                        onChange={e => setIsCompanyDataShown(e.target.checked)}
                    >
                        <Text variant="xxs" color="gray-8">Данные компании</Text>
                    </Checkbox>
                    <Checkbox
                        checked={isShowFuncGroupDescription}
                        onChange={e => dispatch(setIsShowFuncGroupDescription(e.target.checked))}
                    >
                        <Text variant="xxs" color="gray-8">Описание областей</Text>
                    </Checkbox>
                    <Checkbox
                        checked={isShowLevelDescription}
                        onChange={e => dispatch(setIsShowLevelDescription(e.target.checked))}
                    >
                        <Text variant="xxs" color="gray-8">Описание уровней</Text>
                    </Checkbox>
                    {takecountofcompany.map((oldName: string) => {
                        const newName = nameMappingcomp[oldName] || oldName;
                        const isChecked = displayObject.find((d: { key: string; value: boolean }) => d.key === oldName)?.value ?? false;
                        return (
                            <Checkbox
                                key={oldName}
                                checked={isChecked}
                                onChange={() => handleCheckboxChange(oldName)}
                            >
                                <Text variant="xxs" color="gray-8">{newName}</Text>
                            </Checkbox>
                        );
                    })}
                </div>
                <div className={cl.column}>
                    <Text className={cl.title} variant="s" color="gray-8" bold>Уровни квалификации</Text>
                    <Checkbox
                        checked={isHighQualificationShown}
                        onChange={e => setIsHighQualificationShown(e.target.checked)}
                    >
                        <Text variant="xxs" color="gray-8">Высокая квалификация</Text>
                    </Checkbox>
                    <Checkbox
                        checked={isStandardQualificationShown}
                        onChange={e => setIsStandardQualificationShown(e.target.checked)}
                    >
                        <Text variant="xxs" color="gray-8">Стандартная квалификация</Text>
                    </Checkbox>
                    <Checkbox
                        checked={isLowQualificationShown}
                        onChange={e => setIsLowQualificationShown(e.target.checked)}
                    >
                        <Text variant="xxs" color="gray-8">Начальная квалификация</Text>
                    </Checkbox>
                    <div className={cl.column}>
                        <Text className={cl.title} variant="s" color="gray-8" bold>Способы расчета</Text>
                        <Checkbox
                            checked={isShowCompanyTable}
                            onChange={e => dispatch(setIsShowCompanyTable(e.target.checked))}
                        >
                            <Text variant="xxs" color="gray-8">Расчет по компаниям</Text>
                        </Checkbox>
                        <Checkbox
                            checked={isShowEmployeeTable}
                            onChange={e => dispatch(setIsShowEmployeeTable(e.target.checked))}
                        >
                            <Text variant="xxs" color="gray-8">Расчет по сотрудникам</Text>
                        </Checkbox>
                    </div>
                </div>
            </div>
            <div className={cl.separator} />
            {shouldMoveToRightColumn && (
                <div className={cl.block}>
                    <div className={cl.column}>
                        <Text className={cl.title} variant="s" color="gray-8" bold>Структура оплаты</Text>
                        <Checkbox
                            checked={isAllSelected}
                            onChange={e => handleSelectAll(e.target.checked)}
                        >
                            <Text variant="xxs" color="gray-8">Выбрать все</Text>
                        </Checkbox>
                        <br />
                        <div className={cl.block}>
                            <div className={cl.column}>{leftColumnItems}</div>
                            <div className={cl.column}>{rightColumnItems}</div>
                            <div className={cl.column}>{thirdColumnItems}</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Display;
