import React, { FC, useEffect, useState } from 'react';
import cl from './style.module.css';
import Text from 'shared/ui/Text';
import { Checkbox, Input, Radio, Select } from 'antd';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import {
    setCurrency,
    setIsApplyDistrictCoefficient,
    setIncludeTax,
    setIndex,
} from 'shared/store/reducers/commonSlice';
import { useChangeSettingsMutation, useGetSettingsQuery } from 'localentities/settings/model/endpoints';
import { useParams } from 'react-router-dom';
import Spinner from 'shared/ui/Spinner';
import { useAppSelector } from 'shared/hooks/useAppSelector';

import {
    setCountMonths,
    setSurveyPeriod
} from 'shared/store/reducers/commonSlice';
import useDebounce from 'shared/hooks/useDebounce';
import { isNil } from 'lodash';

const Settings: FC = () => {
    const dispatch = useAppDispatch();
    const { reportNumber } = useParams();
    const [currencyValue, setCurrencyValue] = useState<string>('');
    const [taxValue, setTaxValue] = useState<string>('');
    // const [indexValue, setIndexValue] = useState<string>('');

    const [indexValueYear, setIndexValueYear] = useState<string>('');
    const [indexValueAbs, setIndexValueAbs] = useState<string>('');

    const {
        currency,
        isApplyDistrictCoefficient,
        includeTax,
        index,
        surveyPeriod,
        countMonths,
    } = useAppSelector(state => state.common);

    // Выбираем значение, соответствующее выбранному радио-кнопке
    const activeIndexValue = index === 'year' ? indexValueYear : indexValueAbs;
    // Дебаунс активного значения для отправки изменений
    const debouncedActiveIndexValue = useDebounce(activeIndexValue, 300);

    const { data: settings, isLoading: isLoadingSettings } = useGetSettingsQuery(reportNumber ?? '0');
    const [changeSettings, { isLoading: isLoadingChangeSettings }] = useChangeSettingsMutation();
    const debouncedCurrencyValue = useDebounce(currencyValue, 300);
    const debouncedTaxValue = useDebounce(taxValue, 300);
    // const debouncedIndexValue = useDebounce(indexValue, 300);
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        if (settings && !isInitialized) {
            const initialIndex = settings?.index_rates[0]?.index_rate?.toString() || '';
            setIndexValueYear(initialIndex);
            setIndexValueAbs(initialIndex);
            setIsInitialized(true);
    }
    }, [settings, currency]);

    const changeSettingsValue = (value: any, field = 'settings') => {
        if(reportNumber && !isLoadingChangeSettings) {
            changeSettings({
                reportNumber,
                field,
                value,
            });
        }
    };

    useEffect(() => {
        if(!isLoadingChangeSettings && reportNumber && debouncedTaxValue && !debouncedTaxValue.endsWith('.') &&
            debouncedTaxValue !== settings?.tax_rates[0].tax_rate) {
            changeSettingsValue([{
                tax_rate: parseInt(debouncedTaxValue, 10)
            }], 'tax_rates');
        }
    }, [debouncedTaxValue]);

    useEffect(() => {
    if (
        !isLoadingChangeSettings &&
        reportNumber &&
        debouncedActiveIndexValue &&
        !debouncedActiveIndexValue.endsWith('.') &&
        debouncedActiveIndexValue !== settings?.index_rates[0].index_rate
    ) {
        changeSettingsValue(
            [{
                index_rate: parseInt(debouncedActiveIndexValue, 10)
            }],
            'index_rates'
        );
    }
    }, [debouncedActiveIndexValue]);


    useEffect(() => {
        if(!isLoadingChangeSettings && reportNumber && debouncedCurrencyValue && !debouncedCurrencyValue.endsWith('.') &&
            debouncedCurrencyValue !== settings?.currency_types.all_currency_types.find(c => c.type === currency)?.rate.toString()) {
            const selectedCurrency = settings?.currency_types.all_currency_types.find(c => c.type === currency);
            if(selectedCurrency) {
                changeSettingsValue({
                    all_currency_types: [{
                        id: selectedCurrency.id,
                        rate: debouncedCurrencyValue,
                        round: selectedCurrency.round
                    }]
                }, 'currency_types');
            }
        }
    }, [debouncedCurrencyValue]);

    if (isLoadingSettings) {
        return (
            <div className={cl.spinner}>
                <Spinner size="m" />
            </div>
        );
    }

    return (
        <div className={[cl.wrapper, 'settings'].join(' ')}>
            <div className={cl.block}>
                <div className={cl.column}>
                    <Text variant="s" color="gray-8" bold>Валюта отчета</Text>
                    <Select
                        defaultValue={settings?.currency_types.raw_currency_type?.type}
                        onChange={e => {
                            dispatch(setCurrency(e));
                            const selectedCurrency = settings?.currency_types.all_currency_types.find(c => c.type === e);
                            if(selectedCurrency) {
                                changeSettingsValue({
                                    id: selectedCurrency.id,
                                    type: selectedCurrency.type
                                }, 'raw_currency_type');
                            }
                        }}
                        disabled={isLoadingChangeSettings}
                    >
                        {settings?.currency_types.all_currency_types.map(c =>
                            <Select.Option key={c.type} value={c.type}>{c.type}</Select.Option>
                        )}
                    </Select>
                    <div className={cl.data}>
                        <Text variant="s" color="gray-8">Курс валюты</Text>
                        <Input
                            value={currencyValue}
                            onChange={e => !isLoadingChangeSettings &&
                                (/^[0-9]+([.,][0-9]*)?$/.test(e.target.value) || e.target.value === '') &&
                                setCurrencyValue(e.target.value.replace(',', '.'))}
                            disabled={isLoadingChangeSettings}
                        />
                    </div>
                </div>

                <div className={cl.column}>
                    <Text variant="s" color="gray-8" bold>Индексация данных</Text>
                    <Checkbox
                        checked={!!index}
                        onChange={e => {
                            dispatch(setIndex(e.target.checked ? 'abs' : undefined));
                            changeSettingsValue({ indexed: e.target.checked });
                        }}
                        disabled={isLoadingChangeSettings}
                    >
                        <Text variant="xxs" color="gray-8">Данные проиндексированы</Text>
                    </Checkbox>


                    <Radio.Group
                        className={cl.ml}
                        value={index}
                        onChange={e => {
                            dispatch(setIndex(e.target.value));
                            changeSettingsValue(
                                e.target.value === 'abs'
                                    ? { absolute_indexation: true, annual_indexation: false }
                                    : { absolute_indexation: false, annual_indexation: true }
                            );
                        }}
                        disabled={isLoadingChangeSettings}
                        style={{ width: '100%' }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Radio value="year" disabled={!index || isLoadingChangeSettings}>
                                <Text variant="xxs" color={index ? 'gray-8' : 'gray-7'}>
                                    Абсолютный уровень индексации в %
                                </Text>
                            </Radio>
                            <Input
                                value={indexValueYear}
                                onChange={e =>
                                    !isLoadingChangeSettings &&
                                    (/^[0-9]+([.,][0-9]*)?$/.test(e.target.value) || e.target.value === '') &&
                                    setIndexValueYear(e.target.value.replace(',', '.'))
                                }
                                disabled={index !== 'year' || isLoadingChangeSettings}
                            />
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Radio value="abs" disabled={!index || isLoadingChangeSettings}>
                                <Text variant="xxs" color={index ? 'gray-8' : 'gray-7'}>
                                    Годовой уровень индексации в %
                                </Text>
                            </Radio>
                            <Input
                                value={indexValueAbs}
                                onChange={e =>
                                    !isLoadingChangeSettings &&
                                    (/^[0-9]+([.,][0-9]*)?$/.test(e.target.value) || e.target.value === '') &&
                                    setIndexValueAbs(e.target.value.replace(',', '.'))
                                }
                                disabled={index !== 'abs' || isLoadingChangeSettings}
                            />
                        </div>

                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <Text variant="xxs" color="gray-8">Прошло месяцев с даты обзора</Text>
                            <Input
                                value={countMonths}
                                onChange={(e) => {
                                    const value = parseInt(e.target.value);
                                    setCountMonths(isNaN(value) ? 0 : value);
                                    dispatch(setCountMonths(isNaN(value) ? 0 : value));
                                    changeSettingsValue({ months_since_review: isNaN(value) ? 0 : value });
                                }}
                                disabled={index === 'year' || isLoadingChangeSettings}
                            />
                        </div>

                        {/*
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <Text variant="xxs" color="gray-8">Период сбора данных</Text>
                            <Select
                                value={surveyPeriod}
                                onChange={(value) => {
                                    setSurveyPeriod(value);
                                    dispatch(setSurveyPeriod(value));
                                    changeSettingsValue({ data_collection_period: value });
                                }}
                                disabled={isLoadingChangeSettings}
                            >
                                <Select.Option value="Июль 2019">Июль 2019</Select.Option>

                         </Select>
                        </div>
                        */}


                    </Radio.Group>
                </div>
            </div>

            <div className={cl.separator} />

            <div className={cl.block}>
                <div className={cl.column}>
                    <Text variant="s" color="gray-8" bold>Подоходный налог</Text>
                    <Radio.Group
                        className={[cl.column, cl.innerColumn].join(' ')}
                        onChange={e => {
                            dispatch(setIncludeTax(!e.target.value));
                            changeSettingsValue({ tax_included_gross: !e.target.value });
                        }}
                        value={includeTax ? 0 : 1}
                        disabled={isLoadingChangeSettings}
                    >
                        <Radio value={1} disabled={isLoadingChangeSettings}>
                            <Text variant="xxs" color="gray-8">Включен (Gross)</Text>
                        </Radio>
                        <div className={cl.ml}>
                            <div className={cl.data}>
                                <Text variant="xxs" color="gray-8">Ставка налога</Text>
                                <Input
                                    value={taxValue}
                                    onChange={e => !isLoadingChangeSettings &&
                                        (/^[0-9]+([.,][0-9]*)?$/.test(e.target.value) || e.target.value === '') &&
                                        setTaxValue(e.target.value.replace(',', '.'))}
                                    disabled={isLoadingChangeSettings}
                                />
                            </div>
                        </div>
                        <Radio value={0} disabled={isLoadingChangeSettings}>
                            <Text variant="xxs" color="gray-8">Исключен (Net)</Text>
                        </Radio>
                    </Radio.Group>
                </div>

                {/* <div className={cl.column}>
                    <Text variant="s" color="gray-8" bold>Дополнительные настройки</Text>
                    <Text variant="s" color="gray-8">Сравнение данных компании:</Text>
                    <Checkbox
                        defaultChecked={isShowMedian}
                        onChange={e => dispatch(setIsShowMedian(e.target.checked))}
                    >
                        <Text variant="xxs" color="gray-8">Медиана</Text>
                    </Checkbox>
                    <Checkbox
                        defaultChecked={isShowAverage}
                        onChange={e => dispatch(setIsShowAverage(e.target.checked))}
                    >
                        <Text variant="xxs" color="gray-8">Среднее арифметическое</Text>
                    </Checkbox>
                </div> */}
            </div>

            <div className={cl.block}>
                <div className={cl.column}>
                    <Text variant="s" color="gray-8" bold>Районные коэффициенты</Text>
                    <Checkbox
                        checked={isApplyDistrictCoefficient}
                        onChange={e => {
                            dispatch(setIsApplyDistrictCoefficient(e.target.checked));
                            changeSettingsValue({ regional_coefficient_enabled: e.target.checked });
                        }}
                        disabled={isLoadingChangeSettings}
                    >
                        <Text variant="xxs" color="gray-8">РКСН включены</Text>
                    </Checkbox>
                </div>
            </div>
        </div>
    );
};

export default Settings;
