import React, { FC, useEffect, useState } from 'react';
import cl from './style.module.css';
import type { SectionsI } from 'localentities/policy/types'
import Titles from './components/Titles';
import Content from './components/Content';
import { useScreenWidth } from 'shared/hooks/useScreenWidth';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const PolicyReport: FC = () => {
    const navigate = useNavigate();
    const router = useLocation();
    const width = useScreenWidth();
    const [searchParams, _] = useSearchParams();
    const [isOpenTitles, setIsOpenTitles] = useState<boolean>(width > 768);
    const [sections, setSections] = useState<SectionsI>({
        section: searchParams.get('section') ? parseInt(searchParams.get('section') ?? '', 10) : null,
        subSection: searchParams.get('subSection') ? parseInt(searchParams.get('subSection') ?? '', 10) : null
    });

    useEffect(() => {
        navigate(`${router.pathname}?section=${sections.section}&subSection=${sections.subSection}`,
            { replace: true }
        );
    }, [sections]);

    return (
        <div className={cl.wrapper}>
            <Titles isOpenTitles={isOpenTitles} setIsOpenTitles={setIsOpenTitles} sections={sections} setSections={setSections} />
            <Content isOpenTitles={isOpenTitles} sections={sections} setSections={setSections} />
        </div>
    );
};

export default PolicyReport;
