import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ICommonSlice} from './types';

const initialState: ICommonSlice = {
    isAuth: localStorage.getItem('isAuth') === 'true',
    policiesActive: false, // добавляем новое свойство
    filtersChain: '',
    triggerForReportRequest: 0,
    periods: undefined,
    cities: undefined,
    comp_groups: undefined,
    levels: undefined,
    func_groups: undefined,
    qual: undefined,  // по желанию можно задать 'ka' по умолчанию
    isApplyDistrictCoefficient: true,
    currency: '',
    includeTax: true,
    index: undefined,
    isShowAverage: true,
    isShowMedian: false,
    isShowCompanyTable: true,
    isShowEmployeeTable: true,
    columnHeadersInTable: [],
    percentsInTable: [],
    displayObject: [],
    isShowFuncGroupDescription: true,
    isShowLevelDescription: true,
    isShowCompanyCount: true,
    excelDownload: false,
    surveyPeriod: 'Июль 2021',
    countMonths: 0,
};

export const commonSlice = createSlice({
    name: 'commonSlice',
    initialState,
    reducers: {
        setPoliciesActive: (state, action: PayloadAction<boolean>) => {
            state.policiesActive = action.payload;
        },
        setIsAuth: (state, action: PayloadAction<boolean>) => {
            if (action.payload) {
                localStorage.setItem('isAuth', 'true');
            } else {
                localStorage.removeItem('isAuth');
            }
            state.isAuth = action.payload;
        },
        setFiltersChain: (state, action: PayloadAction<string>) => {
            state.filtersChain = action.payload;
        },
        setTriggerForReportRequest: (state) => {
            state.triggerForReportRequest =
                state.triggerForReportRequest < 10000 ? ++state.triggerForReportRequest : 0;
        },
        setPeriods: (state, action: PayloadAction<string>) => {
            state.periods = action.payload;
        },
        setCities: (state, action: PayloadAction<string>) => {
            state.cities = action.payload;
        },
        setCompGroups: (state, action: PayloadAction<string>) => {
            state.comp_groups = action.payload;
        },
        setLevels: (state, action: PayloadAction<string>) => {
            state.levels = action.payload;
        },
        // Переименовываем setQualifications в setQual
        setQual: (state, action: PayloadAction<string>) => {
            state.qual = action.payload;
        },
        setFuncGroups: (state, action: PayloadAction<string>) => {
            state.func_groups = action.payload;
        },
        setIsApplyDistrictCoefficient: (state, action: PayloadAction<boolean>) => {
            state.isApplyDistrictCoefficient = action.payload;
        },
        setCurrency: (state, action: PayloadAction<string>) => {
            state.currency = action.payload;
        },
        setIncludeTax: (state, action: PayloadAction<boolean>) => {
            state.includeTax = action.payload;
        },
        setIndex: (state, action: PayloadAction<'year' | 'abs' | undefined>) => {
            state.index = action.payload;
        },
        setIsShowAverage: (state, action: PayloadAction<boolean>) => {
            state.isShowAverage = action.payload;
        },
        setIsShowMedian: (state, action: PayloadAction<boolean>) => {
            state.isShowMedian = action.payload;
        },
        setIsShowCompanyTable: (state, action: PayloadAction<boolean>) => {
            state.isShowCompanyTable = action.payload;
        },
        setIsShowEmployeeTable: (state, action: PayloadAction<boolean>) => {
            state.isShowEmployeeTable = action.payload;
        },
        setColumnHeadersInTable: (state, action: PayloadAction<string[]>) => {
            state.columnHeadersInTable = [...action.payload];
        },
        setPercentsInTable: (state, action: PayloadAction<string[]>) => {
            state.percentsInTable = action.payload.filter(p =>
                p.toLowerCase() !== 'медиана'
            );
        },
        setIsShowFuncGroupDescription: (state, action: PayloadAction<boolean>) => {
            state.isShowFuncGroupDescription = action.payload;
        },
        setIsShowLevelDescription: (state, action: PayloadAction<boolean>) => {
            state.isShowLevelDescription = action.payload;
        },
        setIsShowCompanyCount: (state, action: PayloadAction<boolean>) => {
            state.isShowCompanyCount = action.payload;
        },
        setExcelDownload: (state, action: PayloadAction<boolean>) => {
            state.excelDownload = action.payload;
        },
        setDisplayObject: (state, action: PayloadAction<{ percents: string[], columnHeaders: string[] }>) => {
            if (state.displayObject.length === 0) {
                state.displayObject.push({key: 'Среднее', value: true});
                action.payload.percents.forEach(p => {
                    if (p !== 'Среднее') {
                        state.displayObject.push({key: p, value: true});
                    }
                });
                action.payload.columnHeaders.forEach(ch => {
                    state.displayObject.push({key: ch, value: true});
                });
            }
        },
        setAllDisplayObjectValues: (state, action: PayloadAction<{ key: string, value: boolean }[]>) => {
            state.displayObject = action.payload;
        },
        setDisplayObjectField: (state, action: PayloadAction<string>) => {
            const index = state.displayObject.findIndex(item => item.key === action.payload);
            if (index !== -1) {
                state.displayObject[index].value = !state.displayObject[index].value;
            }
        },
        clearDisplayObject: (state) => {
            state.displayObject = [];
        },
        setCountMonths: (state, action) => {
            state.countMonths = action.payload;
        },
        setSurveyPeriod: (state, action) => {
            state.surveyPeriod = action.payload;
        },
    }
});

export const {
    setIsAuth, setPoliciesActive, setFiltersChain, setTriggerForReportRequest, setPeriods, setCities,
    setFuncGroups, setCompGroups, setLevels, setIsApplyDistrictCoefficient,
    setCurrency, setIncludeTax, setIndex, setIsShowAverage, setIsShowMedian,
    setColumnHeadersInTable, setPercentsInTable, setDisplayObject, setDisplayObjectField,
    clearDisplayObject, setIsShowCompanyTable, setIsShowEmployeeTable,
    setAllDisplayObjectValues, setIsShowFuncGroupDescription, setIsShowLevelDescription,
    setIsShowCompanyCount, setQual, setExcelDownload, setCountMonths, setSurveyPeriod,
} = commonSlice.actions;

export default commonSlice.reducer;
