import React, {FC, ReactNode, useEffect, useRef, useState} from 'react';
import cl from './style.module.css';
import Text from 'shared/ui/Text';
import Table from 'shared/ui/Table';
import {Collapse, Tooltip, Radio, RadioChangeEvent} from 'antd';
import {useLazyGetSubReportQuery} from 'localentities/report/model/endpoints';
import {useGetSettingsQuery} from 'localentities/settings/model/endpoints';
import {useParams} from 'react-router-dom';
import Spinner from 'shared/ui/Spinner';
import {useSelector} from 'react-redux';
import {useAppSelector} from 'shared/hooks/useAppSelector';
import {useAppDispatch} from 'shared/hooks/useAppDispatch';
import {setCurrency} from 'shared/store/reducers/commonSlice';
import {FieldsNameEnum, ITreeNode} from '../../types';
import InfoCircleIcon from 'shared/assets/icons/info-circle.svg';
import CollapseIcon from 'shared/assets/icons/collapse.svg';
import ExpandIcon from 'shared/assets/icons/expand.svg';
import {ReactSVG} from 'react-svg';
import {RootState} from 'shared/store';

interface ReportWindowProps {
    isOpenFilters: boolean;
    allReports: any[];
}

const ReportWindow: FC<ReportWindowProps> = ({isOpenFilters, allReports}) => {


    const dispatch = useAppDispatch();
    const {reportNumber} = useParams();
    const tableRefs = useRef<(HTMLDivElement | null)[]>([]);
    const {
        filtersChain,
        triggerForReportRequest,
        periods,
        cities,
        func_groups,
        levels,
        comp_groups,
        currency,
        qual, // например "all" или "ka,k3" или "ka,k1,k3"
    } = useAppSelector((state: RootState) => state.common);

    const [allReportData, setAllReportData] = useState<{ [key: string]: any }>({});
    const [getReport, {isFetching: isLoadingSubReport}] = useLazyGetSubReportQuery();
    const {data: settings, isLoading: isLoadingSettings} = useGetSettingsQuery(reportNumber ?? '0');

    const isShowLevelDescription = useSelector((state: RootState) => state.common.isShowLevelDescription);
    const isShowFuncGroupDescription = useSelector((state: RootState) => state.common.isShowFuncGroupDescription);
    const [viewMode, setViewMode] = useState<'standard' | 'transposed'>('standard');
    const [reportData, setReportData] = useState<any>(null);
    const [activeKeys, setActiveKeys] = useState<string[]>([]);

    const handleViewModeChange = (e: RadioChangeEvent) => {
        setViewMode(e.target.value);
    };

    useEffect(() => {
        if (settings && settings.currency_types.raw_currency_type && !currency) {
            dispatch(setCurrency(settings.currency_types.raw_currency_type.type));
        }
    }, [settings, dispatch]);

    function capitalizeFirstLetter(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    useEffect(() => {
        const fetchAllReports = async () => {
            const reportPromises = allReports.map(report =>
                getReport({
                    reportNumber: report.id,
                    filtersChain,
                    periods,
                    cities,
                    func_groups,
                    levels,
                    comp_groups,
                })
                    .unwrap()
                    .then((response) => {
                        // console.log(`Данные отчета ${report.id}:`, response);
                        console.log(response.tree)
                        setReportData(response);
                        return {id: report.id, data: response};

                    })
                    .catch((error) => {
                        console.error(`Ошибка загрузки данных отчета ${report.id}:`, error);
                        return {id: report.id, data: null};
                    })
            );

            const results = await Promise.all(reportPromises);
            const reportDataMap = results.reduce((acc, result) => {
                if (result.data) {
                    acc[result.id] = result.data;
                }
                return acc;
            }, {} as { [key: string]: any });
            // console.log("Все данные отчетов:", reportDataMap);
            setAllReportData(reportDataMap);
        };
        fetchAllReports();
    }, [triggerForReportRequest, allReports, getReport, filtersChain, periods, cities, func_groups, levels, comp_groups]);

    useEffect(() => {
        const topPanelsCount = Object.entries(reportData?.tree ?? {}).length;
        setActiveKeys(Array.from({length: topPanelsCount}, (_, i) => i.toString()));
    }, [reportData]);

    useEffect(() => {
        const handleScroll = (index: number) => {
            const scrollLeft = tableRefs.current[index]?.scrollLeft || 0;
            tableRefs.current.forEach((div, i) => {
                if (i !== index && div) {
                    div.scrollLeft = scrollLeft;
                }
            });
        };
        tableRefs.current.forEach((div, index) => {
            div?.addEventListener('scroll', () => handleScroll(index));
        });
        return () => {
            tableRefs.current.forEach((div, index) => {
                div?.removeEventListener('scroll', () => handleScroll(index));
            });
        };
    }, [tableRefs.current.length]);

    const getComp = (
        height: number,
        arrLength: number,
        title?: string | number,
        node?: ITreeNode,
        collapseKey?: number,
        districtCoefficient?: number,
        countMonths?: number,
        isShowFuncGroupDescription?: boolean,
    ): ReactNode => {
        if (!node) return <></>;
        const getReturnComp = (): React.ReactNode => (
            Object.values(node.values ?? {}).find(v => (v as ITreeNode).type === FieldsNameEnum.ENTITY_TYPE)
                ? <div key={title} className={cl.tables} ref={(el) => tableRefs.current[tableRefs.current.length] = el}>
                    {Object.entries(node.values ?? {}).map(([key, value], i) =>
                        getComp(
                            height + 1,
                            Object.keys(node.values ?? {}).length,
                            key,
                            value as ITreeNode,
                            i,
                            districtCoefficient,
                            countMonths,
                            isShowFuncGroupDescription,
                        ),
                    )}
                </div>
                : Object.entries(node.values ?? {}).map(([key, value], i) =>
                    getComp(
                        height + 1,
                        Object.keys(node.values ?? {}).length,
                        key,
                        value as ITreeNode,
                        i,
                        districtCoefficient,
                        countMonths,
                        isShowFuncGroupDescription,
                    ),
                )
        );
        const collapseProps = height === 0
            ? {
                activeKey: activeKeys,
                onChange: (keys: string | string[]) =>
                    setActiveKeys(typeof keys === 'string' ? [keys] : keys),
            }
            : {
                defaultActiveKey: Array.from({length: arrLength}, (_, i) => i.toString()),
            };

        switch (node.type) {
            case FieldsNameEnum.MONTH_YEAR: {
                const month = title?.toString().substring(0, 1);
                const year = title?.toString().substring(1);
                const dateString = `${year}-${month?.padStart(2, '0')}`;
                const date = new Date(dateString);
                const monthName = capitalizeFirstLetter(date.toLocaleDateString('ru-RU', {month: 'long'}));
                return (
                    <Collapse key={title} expandIconPosition="end" ghost {...collapseProps}>
                        <Collapse.Panel
                            className={cl.collapse}
                            header={
                                <div className={cl.block}>
                                    <Text className={cl.title} variant="small" color="gray-10">
                                        {monthName} {year}
                                    </Text>
                                    {!!node.description && (
                                        <Tooltip
                                            className={cl.tooltip}
                                            placement="bottom"
                                            title={<Text variant="xs" color="white">{node.description}</Text>}
                                            overlayStyle={{maxWidth: 400}}
                                        >
                                            <div className={cl.infoCircle}>
                                                <ReactSVG src={InfoCircleIcon}/>
                                            </div>
                                        </Tooltip>
                                    )}
                                </div>
                            }
                            key={collapseKey ?? 0}
                        >
                            <div className={cl.separator}/>
                            {getReturnComp()}
                        </Collapse.Panel>
                    </Collapse>
                );
            }
            case FieldsNameEnum.CITY: {
                districtCoefficient = settings?.cities_rates.find((c: any) => c.id === node.id)?.regional_rate ?? 1;
                return (
                    <Collapse key={title} expandIconPosition="end" ghost {...collapseProps}>
                        <Collapse.Panel
                            className={cl.collapse}
                            header={
                                <div className={cl.block}>
                                    <Text className={cl.title} variant="small" color="brand-2">
                                        {title}
                                    </Text>
                                    {!!node.description && (
                                        <Tooltip
                                            className={cl.tooltip}
                                            placement="bottom"
                                            title={<Text variant="xs" color="white">{node.description}</Text>}
                                            overlayStyle={{maxWidth: 400}}
                                        >
                                            <div className={cl.infoCircle}>
                                                <ReactSVG src={InfoCircleIcon}/>
                                            </div>
                                        </Tooltip>
                                    )}
                                </div>
                            }
                            key={collapseKey ?? 0}
                        >
                            <div className={cl.separator}/>
                            {getReturnComp()}
                        </Collapse.Panel>
                    </Collapse>
                );
            }
            case FieldsNameEnum.FUNC_GROUP_ROOT:
                return (
                    <Collapse key={title} expandIconPosition="end" ghost {...collapseProps}>
                        <Collapse.Panel
                            className={cl.collapse}
                            header={
                                <div className={cl.block}>
                                    <Text className={cl.title} variant="small" color="gray-7">
                                        {title}
                                    </Text>
                                    {!!node.description && (
                                        <Tooltip
                                            className={cl.tooltip}
                                            placement="bottom"
                                            title={<Text variant="xs" color="white">{node.description}</Text>}
                                            overlayStyle={{maxWidth: 400}}
                                        >
                                            <div className={cl.infoCircle}>
                                                <ReactSVG src={InfoCircleIcon}/>
                                            </div>
                                        </Tooltip>
                                    )}
                                </div>
                            }
                            key={collapseKey ?? 0}
                        >
                            <div className={cl.separator}/>
                            {getReturnComp()}
                        </Collapse.Panel>
                    </Collapse>
                );
            case FieldsNameEnum.FUNC_GROUP:
                return (
                    <Collapse key={title} expandIconPosition="end" ghost {...collapseProps}>
                        <Collapse.Panel
                            className={cl.collapse}
                            header={
                                <div className={cl.block}>
                                    <Text className={cl.title} variant="small" color="gray-10">
                                        {title}
                                    </Text>
                                    {isShowFuncGroupDescription && !!node.description && (
                                        <Tooltip
                                            className={cl.tooltip}
                                            placement="bottom"
                                            title={<Text variant="xs" color="white">{node.description}</Text>}
                                            overlayStyle={{maxWidth: 400}}
                                        >
                                            <div className={cl.infoCircle}>
                                                <ReactSVG src={InfoCircleIcon}/>
                                            </div>
                                        </Tooltip>
                                    )}
                                </div>
                            }
                            key={collapseKey ?? 0}
                        >
                            <div className={cl.separator}/>
                            {getReturnComp()}
                        </Collapse.Panel>
                    </Collapse>
                );
            case FieldsNameEnum.LEVEL:
                return (
                    <Collapse key={title} expandIconPosition="end" ghost {...collapseProps}>
                        <Collapse.Panel
                            className={cl.collapse}
                            header={
                                <div className={cl.block}>
                                    <Text className={cl.title} variant="small" color="gray-10">
                                        {title}
                                    </Text>
                                    {isShowLevelDescription && !!node.description && (
                                        <Tooltip
                                            className={cl.tooltip}
                                            placement="bottom"
                                            title={<Text variant="xs" color="white">{node.description}</Text>}
                                            overlayStyle={{maxWidth: 400}}
                                        >
                                            <div className={cl.infoCircle}>
                                                <ReactSVG src={InfoCircleIcon}/>
                                            </div>
                                        </Tooltip>
                                    )}
                                </div>
                            }
                            key={collapseKey ?? 0}
                        >
                            <div className={cl.separator}/>
                            {getReturnComp()}
                        </Collapse.Panel>
                    </Collapse>
                );
            case FieldsNameEnum.COMP_GROUP:
                return (
                    <Collapse key={title} expandIconPosition="end" ghost {...collapseProps}>
                        <Collapse.Panel
                            className={cl.collapseGroup}
                            header={
                                <div>
                                    <Text className={[cl.block, cl.title].join(' ')} variant="xxs" color="gray-10">
                                        {title}
                                    </Text>
                                </div>
                            }
                            key={collapseKey ?? 0}
                        >
                            {getReturnComp()}
                        </Collapse.Panel>
                    </Collapse>
                );
            case FieldsNameEnum.ENTITY_TYPE: {
                let processedValues: any = node.values;
                if (
                    processedValues &&
                    typeof processedValues === "object" &&
                    "type" in processedValues &&
                    processedValues.type === "quals"
                ) {
                    const qualObj = processedValues.values as Record<string, any>;
                    let selectedQuals: string[] = qual ? qual.split(',').filter((s: string) => s !== "") : [];
                    // Если ни одна галочка выбрана или все – отображаем все таблицы
                    if (selectedQuals.length === 0 || selectedQuals[0] === "all") {
                        selectedQuals = ["ka", "k3", "k2", "k1"];
                    } else {
                        // Если выбраны только некоторые – отображаем таблицу ka + выбранные (из k3, k2, k1)
                        if (!selectedQuals.includes("ka")) {
                            selectedQuals = ["ka", ...selectedQuals];
                        }
                    }
                    // Если таблица kn присутствует – всегда добавляем её
                    if ("kn" in qualObj && !selectedQuals.includes("kn")) {
                        selectedQuals.push("kn");
                    }
                    // Фильтруем выбранные ключи по наличию в qualObj
                    let keysToRender = selectedQuals.filter((k: string) => k in qualObj);
                    const order = ["ka", "k3", "k2", "k1", "kn"];
                    keysToRender = keysToRender.sort((a: string, b: string) => order.indexOf(a) - order.indexOf(b));
                    // console.log(title)
                    return (
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {keysToRender.map((key: string) => {
                                const tableData = qualObj[key];
                                const titleWithQual = `${title}`;

                                if (tableData && Array.isArray(tableData.values)) {
                                    const arr = tableData.values as any[];
                                    if (title === "Сотрудник") {
                                        // console.log("СОТР")
                                        // const rowsWithoutPercent = arr.filter(
                                        //     (row: any) => row[0] !== "% от Доход в год"
                                            // );

                                        const filteredArr = arr.map((row: any) => {
                                            // Проверяем, это ли строка "% от Доход в год"
                                            if (row[0] === "% от Доход в год") {
                                                return [
                                                    row[0],
                                                    // Проходимся по элементам row[1] и меняем "100 %" на ""
                                                    row[1].map((pair: any) => {
                                                        // pair — обычно [заголовокКолонки, значение]
                                                        if (pair[1] === "100 %") {
                                                            return [pair[0], ""];
                                                        }
                                                        return pair;
                                                    }),
                                                ];
                                            } else {
                                                // Логика для остальных строк
                                                return [
                                                    row[0],
                                                    row[1].filter((pair: any) => String(pair[0])),
                                                ];
                                            }
                                        });
                                        // console.log(filteredArr)


                                        const numberObj = filteredArr.map((val: any) =>
                                            val[1].map((v: any) => ({
                                                percent: String(val[0]),
                                                columnHeader: String(v[0]),
                                                number: v[1] as number | null,
                                            }))
                                        );
                                        return (
                                            <div key={key} style={{marginBottom: '10px'}}>
                                                <Table
                                                    title={titleWithQual}
                                                    extraHeaderValue={key.toUpperCase()}
                                                    numberObj={numberObj}
                                                    percents={filteredArr.map((v: any) => String(v[0]))}
                                                    columnHeaders={
                                                        filteredArr.length > 0 && Array.isArray(filteredArr[0][1])
                                                            ? filteredArr[0][1].map((v: any) => String(v[0]))
                                                            : []
                                                    }
                                                    districtCoefficient={districtCoefficient ?? 1}
                                                    viewMode={viewMode}
                                                />
                                            </div>
                                        );
                                    } else {
                                        // console.log("КОМП1")
                                        const filteredArr = arr.map((row: any) => {
                                            // Проверяем, это ли строка "% от Доход в год"
                                            if (row[0] === "% от Доход в год") {
                                                return [
                                                    row[0],
                                                    // Проходимся по элементам row[1] и меняем "100 %" на ""
                                                    row[1].map((pair: any) => {
                                                        // pair — обычно [заголовокКолонки, значение]
                                                        if (pair[1] === "100 %") {
                                                            return [pair[0], ""];
                                                        }
                                                        return pair;
                                                    }),
                                                ];
                                            } else {
                                                // Логика для остальных строк
                                                return [
                                                    row[0],
                                                    row[1].filter((pair: any) => String(pair[0])),
                                                ];
                                            }
                                        });
                                        // console.log(filteredArr)

                                        const numberObj = filteredArr.map((val: any) =>
                                            val[1].map((v: any) => ({
                                                percent: String(val[0]),
                                                columnHeader: String(v[0]),
                                                number: v[1] as number | null,
                                            }))
                                        );
                                        return (
                                            <div key={key} style={{marginBottom: '10px'}}>
                                                <Table
                                                    title={titleWithQual}
                                                    numberObj={numberObj}
                                                    percents={arr.map((v: any) => String(v[0]))}
                                                    columnHeaders={
                                                        arr.length > 0 && Array.isArray(arr[0][1])
                                                            ? arr[0][1].map((v: any) => String(v[0]))
                                                            : []
                                                    }
                                                    districtCoefficient={districtCoefficient ?? 1}
                                                    viewMode={viewMode}
                                                />
                                            </div>
                                        );
                                    }
                                }
                                return null;
                            })}
                        </div>
                    );
                } else if (processedValues && Array.isArray(processedValues.values)) {
                    const arr_local = processedValues.values as any[];


                    if (title === "Сотрудник") {
                        // console.log("СОТР2")

                        const rowsWithoutPercent = arr_local.filter(
                            (row: any) => row[0] !== "% от Доход в год"
                        );

                        const filteredArr = arr_local.map((row: any) => {
                            // Проверяем, это ли строка "% от Доход в год"
                            if (row[0] === "% от Доход в год") {
                                return [
                                    row[0],
                                    // Проходимся по элементам row[1] и меняем "100 %" на ""
                                    row[1].map((pair: any) => {
                                        // pair — обычно [заголовокКолонки, значение]
                                        if (pair[1] === "100 %") {
                                            return [pair[0], ""];
                                        }
                                        return pair;
                                    }),
                                ];
                            } else {
                                // Логика для остальных строк
                                return [
                                    row[0],
                                    row[1].filter((pair: any) => String(pair[0])),
                                ];
                            }
                        });
                        // console.log(filteredArr)
                        const filteredNumberObj = filteredArr.map((val: any) =>
                            val[1].map((v: any) => ({
                                percent: String(val[0]),
                                columnHeader: String(v[0]),
                                number: v[1] as number | null,
                            }))
                        );
                        return (
                            <Table
                                key={title}
                                title={title?.toString() ?? ''}
                                numberObj={filteredNumberObj}
                                percents={filteredArr.map((v: any) => String(v[0]))}
                                columnHeaders={
                                    filteredArr.length > 0 && Array.isArray(filteredArr[0][1])
                                        ? filteredArr[0][1].map((v: any) => String(v[0]))
                                        : []
                                }
                                districtCoefficient={districtCoefficient ?? 1}
                                viewMode={viewMode}
                            />
                        );
                    } else {
                        // console.log("КОМП2")
                        const filteredArr = arr_local.map((row: any) => {
                            // Проверяем, это ли строка "% от Доход в год"
                            if (row[0] === "% от Доход в год") {
                                return [
                                    row[0],
                                    // Проходимся по элементам row[1] и меняем "100 %" на ""
                                    row[1].map((pair: any) => {
                                        // pair — обычно [заголовокКолонки, значение]
                                        if (pair[1] === "100 %") {
                                            return [pair[0], ""];
                                        }
                                        return pair;
                                    }),
                                ];
                            } else {
                                // Логика для остальных строк
                                return [
                                    row[0],
                                    row[1].filter((pair: any) => String(pair[0])),
                                ];
                            }
                        });
                        // console.log(filteredArr)
                        const filteredNumberObj = filteredArr.map((val: any) =>
                            val[1].map((v: any) => ({
                                percent: String(val[0]),
                                columnHeader: String(v[0]),
                                number: v[1] as number | null,
                            }))
                        );
                        return (
                            <Table
                                key={title}
                                title={title?.toString() ?? ''}
                                numberObj={filteredNumberObj}
                                percents={filteredArr.map((v: any) => String(v[0]))}
                                columnHeaders={
                                    filteredArr.length > 0 && Array.isArray(filteredArr[0][1])
                                        ? filteredArr[0][1].map((v: any) => String(v[0]))
                                        : []
                                }
                                districtCoefficient={districtCoefficient ?? 1}
                                viewMode={viewMode}
                            />
                        );
                    }
                }
                return <></>;
            }
            default:
                return <React.Fragment key={title}/>;
        }
    };

    const handleClickExpandButton = () => {
        const topPanelsCount = Object.entries(reportData?.tree ?? {}).length;
        if (activeKeys.length === topPanelsCount) {
            setActiveKeys([]);
        } else {
            setActiveKeys(Array.from({length: topPanelsCount}, (_, i) => i.toString()));
        }
    };

    return (
        <div className={isOpenFilters ? cl.wrapper : [cl.wrapper, cl.wrapperFull].join(' ')}>
            <div
                className={cl.radioGroupMargin}
                style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
            >
                <Radio.Group onChange={handleViewModeChange} value={viewMode}>
                    <Radio value="standard">Стандартный</Radio>
                    <Radio value="transposed">Транспонированный</Radio>
                </Radio.Group>
                <ReactSVG
                    className={cl.chooseAll}
                    src={
                        activeKeys.length === Object.entries(reportData?.tree ?? {}).length
                            ? CollapseIcon
                            : ExpandIcon
                    }
                    role="button"
                    tabIndex={0}
                    style={{cursor: 'pointer', marginRight: '38px', transform: 'scale(1.25)'}}
                    onClick={handleClickExpandButton}
                    onKeyDown={e => e.key === 'Enter' && handleClickExpandButton()}
                />
            </div>
            <div className={cl.window}>
                {isLoadingSubReport || isLoadingSettings ? (
                    <Spinner size="l"/>
                ) : (
                    Object.entries(reportData?.tree ?? {}).map(
                        ([key, value], i, arr) =>
                            getComp(0, arr.length, key, value as ITreeNode, i, undefined, undefined, isShowFuncGroupDescription)
                    )
                )}
            </div>
        </div>
    );
};

export default ReportWindow;
