// shared/ui/Table/index.tsx
import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import cl from './style.module.css';
import Text from '../Text';
import {useAppSelector} from 'shared/hooks/useAppSelector';
import {useGetSettingsQuery} from 'localentities/settings/model/endpoints';
import {useParams} from 'react-router-dom';
import {useAppDispatch} from 'shared/hooks/useAppDispatch';
import {
    setColumnHeadersInTable,
    setPercentsInTable,
    setDisplayObject,
} from 'shared/store/reducers/commonSlice';
import cn from 'clsx';

// import {isNil} from 'lodash';

interface TableProps {
    title: string;
    numberObj: {
        percent: string;
        columnHeader: string;
        number: number | null;
    }[][];
    percents: string[];
    columnHeaders: string[];
    districtCoefficient: number;
    viewMode: 'standard' | 'transposed';
    extraHeaderValue?: string;
}

/**
 * Мемоизированный компонент для рендера отдельного столбца.
 */
const TableColumn: FC<{
    ch: string;
    percents: string[];
    getNumberWithCoefficient: (ch: string, percent: string, flagCoefficient: boolean) => string;
    checkVisibilityOfRow: (value: string) => boolean;
    checkSubordinateEmployees: (i: number) => boolean;
}> = React.memo(({ch, percents, getNumberWithCoefficient, checkVisibilityOfRow, checkSubordinateEmployees}) => {
    return (
        <div className={cl.column}>
            {checkVisibilityOfRow(ch) && (
                <>
                    <Text className={cl.title} variant="xxs" color="gray-9">
                        {ch}
                    </Text>
                    <div className={cl.separator}/>
                    {percents.map((p, i) =>
                            checkSubordinateEmployees(i) && checkVisibilityOfRow(p) && (
                                <Text
                                    key={`${ch}-${p}`}
                                    className={cn(cl.number)}
                                    variant="xs"
                                    color="gray-8"
                                >
                                    {getNumberWithCoefficient(ch, p, i < 6)}
                                </Text>
                            )
                    )}
                </>
            )}
        </div>
    );
});

const Table: FC<TableProps> = ({
                                   title,
                                   viewMode,
                                   numberObj,
                                   percents,
                                   columnHeaders,
                                   districtCoefficient,
                                   extraHeaderValue
                               }) => {
    const dispatch = useAppDispatch();
    const {reportNumber} = useParams();
    const {
        isApplyDistrictCoefficient,
        currency,
        includeTax,
        index,
        //isShowMedian,
        //isShowAverage,
        isShowCompanyTable,
        isShowEmployeeTable,
        displayObject,
        countMonths,
    } = useAppSelector(state => state.common);
    const {data: settings} = useGetSettingsQuery(reportNumber ?? '0');
    const [showAll, setShowAll] = useState(false);

    // Функция для переключения режима "показать больше/меньше"
    const toggleShowAll = useCallback(() => {
        setShowAll(prev => !prev);
    }, []);

    const orderedPercents = useMemo(
        () => ['Среднее', '10%', '25%', '50%', '75%', '90%', '% от Доход в год'],
        []
    );
    // const offset = columnHeaders.length;

    useEffect(() => {
        if (!displayObject.length) {
            dispatch(setColumnHeadersInTable(columnHeaders));
            if (title.toLowerCase() !== 'компания' && title.toLowerCase() !== 'компании') {
                dispatch(setPercentsInTable([...percents, 'Кол-во компаний']));
                dispatch(
                    setDisplayObject({
                        percents: [...percents, 'Кол-во компаний'],
                        columnHeaders,
                    })
                );
            }
        }
    }, [columnHeaders, dispatch, displayObject.length, percents, title]);

    // Мемоизированные функции
    const checkSubordinateEmployees = useCallback(
        (i: number) =>
            (i === percents.length - 1 &&
                title.toLowerCase() !== 'сотрудник' &&
                title.toLowerCase() !== 'сотрудники') || i !== percents.length - 1,
        [percents.length, title]
    );

    const filteredPercents = useMemo(
        () => percents.filter(p => p === 'Кол-во компаний' || p === 'Подчинённых сотр.'),
        [percents]
    );

    const checkVisibilityOfRow = useCallback(
        (value: string) =>
            displayObject.some(d => d.key.toLowerCase() === value.toLowerCase() && d.value),
        [displayObject]
    );

    const {currencyCoefficient, round} = useMemo(() => {
        const currentCurrency = settings?.currency_types.all_currency_types.find(c => c.type === currency);
        return {
            currencyCoefficient: currentCurrency?.rate || 1,
            round: currentCurrency?.round || 1,
        };
    }, [currency, settings]);

    const getNumberWithCoefficient = useCallback(
        (ch: string, percent: string, flagCoefficient: boolean) => {
            let number = numberObj
                .find(no => no.length && no[0].percent === percent)
                ?.find(no => (no?.columnHeader ?? '') === ch)?.number;

            if (number != null && flagCoefficient) {
                if (isApplyDistrictCoefficient) {
                    number *= districtCoefficient;
                    if (isNaN(number)) return '';
                }
                if (includeTax && settings?.tax_rates[0]?.tax_rate) {
                    const taxRate = settings.tax_rates[0].tax_rate;
                    number = number * (1 - taxRate / 100);
                    if (isNaN(number)) return '';
                }
                if (index && settings?.index_rates[0]?.index_rate) {
                    switch (index) {
                        case 'year':
                            number *= 1 + settings.index_rates[0].index_rate / 100;
                            break;
                        case 'abs':
                            number *= 1 + (countMonths / 12) * settings.index_rates[0].index_rate / 100;
                            break;
                    }
                    if (isNaN(number)) return '';
                }
                number = Math.round((number / currencyCoefficient) * 100);
                number = Math.round(number / round) * round;
                if (isNaN(number)) return '';
            }
            return number ? number.toLocaleString('en-US').replace(/,/g, ' ') : ' ';
        },
        [
            countMonths,
            currencyCoefficient,
            districtCoefficient,
            includeTax,
            index,
            isApplyDistrictCoefficient,
            numberObj,
            round,
            settings,
        ]
    );

    // Вместо раннего возврата мы вычисляем переменную,
    // которая определяет, нужно ли отрисовывать таблицу.
    const shouldRenderTable = !(
        ((title.toLowerCase() === 'компания' || title.toLowerCase() === 'компании') && !isShowCompanyTable) ||
        ((title.toLowerCase() === 'сотрудник' || title.toLowerCase() === 'сотрудники') && !isShowEmployeeTable)
    );

    // Рендер стандартного вида таблицы
    const renderStandardData = useCallback(() => {
        // Мэп для квалификаций
        const qualMapping: { [key: string]: string } = {
            kn: "Без квалификации",
            ka: "Все квалификации",
            k3: "Высокая квалификация",
            k2: "Стандартная квалификация",
            k1: "Начальная квалификация",
        };

        return (
            <div className={cl.marginWrapper}>
                <div className={cl.wrapper}>
                    {/* Общий контейнер для заголовочной строки и основной строки */}
                    <div className={cl.tableWrapper}>
                        {/* Заголовочная строка */}
                        <div className={cl.headerRow}>
                            <div className={cl.leftHeaderCell}>
                                <Text className={cl.paddingLeft} variant="xs" color="gray-9" bold>
                                    {extraHeaderValue
                                        ? (qualMapping[extraHeaderValue.toLowerCase()] || extraHeaderValue)
                                        : ' '}
                                </Text>
                            </div>
                            {columnHeaders.map((_, idx) => (
                                <div key={idx} className={cl.headerCell}/>
                            ))}
                        </div>
                        {/* Основная строка – левая колонка и правая колонка */}
                        <div className={cl.rowContainer}>
                            {/* Левая колонка с заголовком "СОТРУДНИКИ" и процентами */}
                            <div className={cl.mainColumn}>
                                <Text className={cl.paddingLeft} variant="xxs" color="gray-9">
                                    {title.replace('Сотрудник', 'СОТРУДНИКИ').replace('Компания', 'КОМПАНИИ')}
                                </Text>
                                <div className={cl.separator}/>
                                {percents.map(
                                    (p, i) =>
                                        checkSubordinateEmployees(i) &&
                                        checkVisibilityOfRow(p) && (
                                            <Text
                                                key={p}
                                                className={cn(cl.percent, cl.paddingLeft)}
                                                variant="xxs"
                                                color="gray-9"
                                            >
                                                {p.replace('сотр.', '')}
                                            </Text>
                                        )
                                )}
                            </div>
                            {/* Правая колонка – данные таблицы */}
                            <div className={cl.otherTable}>
                                {columnHeaders.map(ch => (
                                    <TableColumn
                                        key={ch}
                                        ch={ch}
                                        percents={percents}
                                        getNumberWithCoefficient={getNumberWithCoefficient}
                                        checkVisibilityOfRow={checkVisibilityOfRow}
                                        checkSubordinateEmployees={checkSubordinateEmployees}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }, [
        percents,
        columnHeaders,
        title,
        checkSubordinateEmployees,
        checkVisibilityOfRow,
        getNumberWithCoefficient,
        extraHeaderValue,
    ]);


    // Рендер транспонированного вида таблицы
    const renderTransposedData = useCallback(() => {
        console.log('orderedPercents:', orderedPercents);

        const visiblePercents = orderedPercents.filter(percent => checkVisibilityOfRow(percent));
        console.log('visiblePercents:', visiblePercents);
        return (
            <div className={cl.marginWrapper}>
                <div className={cl.trwrapper}>
                    <div className={cl.trmainColumn}>
                        <div className={cl.trheaderRow}>
                            <Text className={[cl.title, cl.paddingLeft].join(' ')} variant="xxs" color="gray-9">
                                {title.replace('Сотрудник', 'СОТРУДНИКИ').replace('Компания', 'КОМПАНИИ')}
                            </Text>
                            <div className={cl.pracentTitles}>
                                {visiblePercents.map(
                                    (percent, i) =>
                                        checkVisibilityOfRow(percent) && (
                                            <Text
                                                key={i}
                                                className={cn(cl.title, cl.columnNumberspace, cl.rightPosition)}
                                                variant="xxs"
                                                color="gray-9"
                                            >
                                                {percent}
                                            </Text>
                                        )
                                )}
                            </div>
                        </div>
                        <div className={cl.trseparator}/>
                        {columnHeaders.slice(0, showAll ? columnHeaders.length : 7).map((ch) => (
                            <div key={ch} className={cl.trcolumn}>
                                {checkVisibilityOfRow(ch) && (
                                    <>
                                        <div className={cn(cl.trcolumNumber, cl.columnTitle)}>
                                            <Text variant="xxs" color="gray-9">
                                                {ch}
                                            </Text>
                                        </div>
                                        {orderedPercents.map((p, idx) =>
                                                checkVisibilityOfRow(p) &&
                                                checkSubordinateEmployees(idx) && (
                                                    <div className={cn(cl.columnNumberspace)} key={`${ch}-${p}`}>
                                                        <Text className={cn(cl.trnumber)} variant="xs" color="gray-8">
                                                            {getNumberWithCoefficient(ch, p, idx < 6)}
                                                        </Text>
                                                    </div>
                                                )
                                        )}
                                    </>
                                )}
                            </div>
                        ))}

                        <div className={cl.trseparator}/>
                        {filteredPercents.map((p, i) => {
                            // const adjustedIndex = i + offset;
                            if (checkSubordinateEmployees(i) && checkVisibilityOfRow(p)) {
                                return (
                                    <div key={p} className={cl.trcolumn}>
                                        <div className={cn(cl.trcolumNumber, cl.columnTitle)}>
                                            <Text variant="xxs" color="gray-9">
                                                {p}
                                            </Text>
                                        </div>
                                        {columnHeaders.map((ch, headerIndex) => {
                                            if (headerIndex < 7 && checkVisibilityOfRow(orderedPercents[headerIndex])) {
                                                const number = numberObj.find(no => no.length && no[0].percent === p)
                                                    ?.find(no => (no?.columnHeader ?? '') === ch)
                                                    ?.number;
                                                return (
                                                    <div key={`${ch}-${p}-${i}`} className={cn(cl.columnNumberspace)}>
                                                        <Text className={cn(cl.trnumber)} variant="xs" color="gray-8">
                                                            {number ? number.toLocaleString('ru-RU').replaceAll(',', ' ') : ''}
                                                        </Text>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>
                                );
                            }
                            return null;
                        })}
                        <button className={cl.buttonmore} onClick={toggleShowAll}>
                            {showAll ? 'Показать меньше' : 'Показать больше'}
                        </button>
                    </div>
                </div>
            </div>
        );
    }, [
        orderedPercents,
        checkVisibilityOfRow,
        columnHeaders,
        filteredPercents,
        // offset,
        numberObj,
        showAll,
        title,
        checkSubordinateEmployees,
        getNumberWithCoefficient,
        toggleShowAll,
    ]);

    // Финальный рендер. Все хуки вызываются всегда, а условие для отображения таблицы
    // вынесено в переменную shouldRenderTable.
    return shouldRenderTable ? (
        <div>
            {viewMode === 'transposed' ? renderTransposedData() : renderStandardData()}
        </div>
    ) : null;
};

export default React.memo(Table);
