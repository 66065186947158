import React, {FC, HTMLAttributes, useEffect, useMemo, useRef, useState} from 'react';
import SlideBlock from 'shared/ui/SlideBlock';
import cl from './style.module.css';
import Text from 'shared/ui/Text';
import {TreeSelect, Tag} from 'antd';
import {useDrag, useDrop} from 'react-dnd';
import {HolderOutlined} from '@ant-design/icons';
import {FiltersNameEnum, IAntdOption, ISaveTemplate} from 'localentities/filter/types';
import {getCorrectDate} from 'shared/utils';
import {useAppDispatch} from 'shared/hooks/useAppDispatch';
import {
    setCities,
    setCompGroups,
    setFuncGroups,
    setPeriods,
    setLevels, setTriggerForReportRequest,
} from 'shared/store/reducers/commonSlice';
import {TemplateResponse} from '../../model/schemas';
import {useAppSelector} from 'shared/hooks/useAppSelector';
import PlusIcon from 'shared/assets/icons/plus-circle.svg';
import {ReactSVG} from 'react-svg';
import {SET_ALL_CONSTANT_ID} from 'shared/constants';

interface FilterProps extends HTMLAttributes<HTMLDivElement> {
    title: string;
    titleKey: string;
    index: number;
    options: IAntdOption[];
    cbDrag?: (dragIndex: number, hoverIndex: number) => void;
    template?: TemplateResponse;
    triggerTemplate?: boolean;
    setIsOpenCitiesSort?: () => void;
    setIsOpenFuncGroupSort?: () => void;
    onFilterChange?: (changed: boolean) => void;
}


const Filter: FC<FilterProps> = ({
                                     className,
                                     title,
                                     titleKey,
                                     index,
                                     options,
                                     cbDrag,
                                     template,
                                     triggerTemplate,
                                     setIsOpenCitiesSort,
                                     setIsOpenFuncGroupSort,
                                     onFilterChange,
                                 }) => {
    const dispatch = useAppDispatch();
    const {cities} = useAppSelector(state => state.common);
    const {func_groups} = useAppSelector(state => state.common);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const ref = useRef<HTMLDivElement>(null);
    const [keys, setKeys] = useState<number[]>([]);

    const [isSetAll, setIsSetAll] = useState<boolean>(false);


    const [{isDragging}, dragRef] = useDrag({
        type: 'item',
        item: {index},
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });


    const [, dropRef] = useDrop({
        accept: 'item',
        hover: (item: { index: number }, monitor) => {
            const dragIndex = item.index;
            const hoverIndex = index;
            const hoverBoundingRect = wrapperRef.current?.getBoundingClientRect();
            if (hoverBoundingRect && monitor) {
                const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect?.top) / 2;
                const hoverActualY = monitor.getClientOffset()!.y - hoverBoundingRect.top;

                if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
                if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

                if (cbDrag) {
                    cbDrag(dragIndex, hoverIndex);
                }
                item.index = hoverIndex;
            }
        },
    });


    const dragDropRef = dragRef(dropRef(wrapperRef));

    const handleChange = (filterName: string, value: number[], isUnblockButton = true) => {
        setKeys(value);
        const values = value.filter(v => v !== SET_ALL_CONSTANT_ID)?.join(',');

        localStorage.setItem(`filter_${filterName}`, JSON.stringify(value));
        onFilterChange && isUnblockButton && onFilterChange(true);
        switch (filterName) {
            case 'periods':
                dispatch(setPeriods(values));
                break;
            case 'subjects':
                dispatch(setCities(values));
                break;
            case 'func_groups':
                dispatch(setFuncGroups(values));
                break;
            case 'levels':
                dispatch(setLevels(values));
                break;
            case 'comp_groups':
                dispatch(setCompGroups(values));
                break;
            default:
                break;
        }
    };

    const handleRemoveItem = (itemValue: number) => {
        const updatedKeys = keys.filter(key => key !== itemValue);

        setKeys(updatedKeys);

        // Обновляем состояние в редуксе, если необходимо
        handleChange(titleKey, updatedKeys);
    };


    const handleChangeAll = (filterName: string, value: number[], isUnblockButton = true) => {
        if (filterName !== 'levels' && filterName !== 'comp_groups') {
            handleChange(filterName, value, isUnblockButton);
            return;
        }
        const isAll = options.every(o => value.some(k => k === o.value));
        if (isSetAll && isAll) {
            setIsSetAll(false);
            handleChange(filterName, [], isUnblockButton);
        } else if (!isSetAll && isAll) {
            setIsSetAll(true);
            handleChange(filterName, [...value, SET_ALL_CONSTANT_ID], isUnblockButton);
        } else if (!isSetAll && !isAll && value.some(v => v === SET_ALL_CONSTANT_ID)) {
            setIsSetAll(true);
            handleChange(filterName, [...options.map(o => o.value), SET_ALL_CONSTANT_ID], isUnblockButton);
        } else {
            setIsSetAll(false);
            handleChange(filterName, value.filter(v => v !== SET_ALL_CONSTANT_ID), isUnblockButton);
        }
    };

    useEffect(() => {
        if (titleKey === 'func_groups') {
            // Предполагается, что func_groups - это строка с разделителями
            const initialGroupIds = func_groups ? func_groups.split(',').map(Number) : [];

            // Удалите специальные идентификаторы, если они есть
            const filteredGroupIds = initialGroupIds?.filter(id => id !== -1000000 && id !== SET_ALL_CONSTANT_ID);

            setKeys(filteredGroupIds);
        }
        // Добавьте похожую логику для других фильтров, если это необходимо
    }, [func_groups, titleKey]);


    useEffect(() => {
        if (template) {
            (JSON.parse(template.json_data) as ISaveTemplate)?.filter?.map(t =>
                t.filterName === titleKey &&
                handleChangeAll(t.filterName, t.keys.length ? t.keys.split(',').map(Number) : [])
            );
        }

    }, [template, triggerTemplate]);

    useEffect(() => {
        const localdata = localStorage.getItem(`filter_${titleKey}`);
        if (cities !== undefined && titleKey === 'subjects') {
            handleChangeAll(
                'subjects',
                cities ? cities?.split(',')?.map(Number) : [],
                cities?.split(',')?.map(Number).toString() !== JSON.parse(localdata ?? '').toString()
            );
        }
        if (func_groups !== undefined && titleKey === 'func_groups') {
            handleChangeAll(
                'func_groups',
                func_groups ? func_groups?.split(',')?.map(Number) : [],
                func_groups?.split(',')?.map(Number).toString() !== JSON.parse(localdata ?? '').toString()
            );
        }
    }, [cities, func_groups]);


    const correctOptions = useMemo(() => {
        const allSetOptions: IAntdOption[] = titleKey === 'periods'
            ? [...options.map(o => ({...o, title: getCorrectDate(o.title)}))]
            : [...options];

        if (titleKey === 'levels' || titleKey === 'comp_groups') {
            allSetOptions.unshift({
                value: SET_ALL_CONSTANT_ID,
                title: 'Выбрать все',
            });
        }
        return allSetOptions;
    }, [title, options]);


    const getLengthOptions = (option: IAntdOption[]) => {
        if (option.length) {
            let count = 0;
            option.forEach(opt => opt?.children ? count += opt.children.length : count += 1);
            return count;
        }

        return 0;
    };

    const getTitle = () => (
        <div className={cl.title}>
            <HolderOutlined className={cl.dragIcon}/>
            <Text className={cl.titleText} variant="s" color="primary">{title}</Text>
            <div className={cl.rightTitleWrapper}>
                <Text className={cl.allSet} variant="xxs" color="gray-7">
                    {keys?.some(k => k === SET_ALL_CONSTANT_ID)
                        ? keys.filter(k => k !== SET_ALL_CONSTANT_ID).length : (keys?.length ?? 0)}/{getLengthOptions(options)}
                </Text>
                {title === 'Город' &&
                    <ReactSVG
                        className={cl.iconCircle}
                        src={PlusIcon}
                        role="button"
                        tabIndex={0}
                        onClick={e => {
                            e.stopPropagation();
                            if (setIsOpenCitiesSort) {
                                setIsOpenCitiesSort();
                            }
                        }}
                        onKeyDown={e => {
                            if (e.key === 'Enter' && setIsOpenCitiesSort) {
                                e.stopPropagation();
                                setIsOpenCitiesSort();
                            }
                        }}
                    />
                }
                {title === 'Область специализации' &&
                    <ReactSVG
                        className={cl.iconCircle}
                        src={PlusIcon}
                        role="button"
                        tabIndex={0}
                        onClick={e => {
                            e.stopPropagation();
                            if (setIsOpenFuncGroupSort) {
                                setIsOpenFuncGroupSort();
                            }
                        }}
                        onKeyDown={e => {
                            if (e.key === 'Enter' && setIsOpenFuncGroupSort) {
                                e.stopPropagation();
                                setIsOpenFuncGroupSort();
                            }
                        }}
                    />
                }
            </div>
        </div>
    );

    useEffect(() => {
        const localdata = localStorage.getItem(`filter_${titleKey}`)


        if (title === FiltersNameEnum.periods.value ||
            title === FiltersNameEnum.subjects.value ||
            title === FiltersNameEnum.func_groups.value
        ) {
            handleChangeAll(titleKey, options[0].children?.length ? [options[0].children[0].value] : [options[0].value], false);
        } else {
            handleChangeAll(titleKey, options.map(o => o.value), false);
        }
        dispatch(setTriggerForReportRequest());

        if (localdata) {
            // setKeys(JSON.parse(localdata))
            handleChangeAll(titleKey, JSON.parse(localdata), false)
        }

    }, []);

    const renderTreeSelect = () => {
        if (['subjects', 'func_groups'].includes(titleKey)) {
            return null;
        }
        return (
            <TreeSelect
                showSearch
                style={{width: '100%'}}
                defaultValue={keys}
                dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                placeholder="Выберите из списка"
                allowClear
                treeCheckable
                value={keys}
                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                onChange={value => handleChangeAll(titleKey, value)}
                tagRender={(props) => {
                    const {label, value, closable, onClose} = props;
                    if (value === SET_ALL_CONSTANT_ID) {
                        // Возвращаем пустой фрагмент вместо null,
                        // чтобы тег "Выбрать все" не отображался
                        return <></>;
                    }
                    return (
                        <Tag closable={closable} onClose={onClose} className="myCustomTag">
                            {label}
                        </Tag>
                    );
                }}
                filterTreeNode={(input, option) => {
                    const text = option?.title?.toString().trim().split(' ');
                    if (text?.length && /\d+\./.test(text[0])) {
                        return (
                            text.filter((_, i) => !!i)
                                .join(' ')
                                .toLowerCase()
                                .startsWith(input.toLowerCase()) ||
                            text.join(' ').toLowerCase().startsWith(input.toLowerCase())
                        );
                    }
                    return (
                        typeof option?.title === 'string' &&
                        option?.title.toLowerCase().startsWith(input.toLowerCase())
                    );
                }}
                treeData={correctOptions}
            />
        );
    };


    const renderSelectedItems = () => {
        if (titleKey === 'func_groups' || titleKey === 'subjects') {
            let selectedItems: IAntdOption[];
            if (titleKey === 'func_groups') {
                const allOptions: IAntdOption[] = [];
                options.forEach(opt => opt.children?.forEach(child => allOptions.push(child)))
                selectedItems = allOptions.filter(option => keys.includes(option.value));
            } else {
                selectedItems = options.filter(option => keys.includes(option.value));
            }


            selectedItems.sort((a, b) => {
                const aTitle = typeof a.title === 'string' ? a.title.toLowerCase() : '';
                const bTitle = typeof b.title === 'string' ? b.title.toLowerCase() : '';
                return aTitle.localeCompare(bTitle, 'ru');
            });

            return (
                <div className={cl.selectedItemsContainer}>
                    {selectedItems.map(item => (
                        <div key={item.value} className={cl.selectedItem}>
                            {item.title}
                            <span className={cl.removeItem} onClick={() => handleRemoveItem(item.value)}>&times;</span>
                        </div>
                    ))}
                    {selectedItems.length === 0 && (
                        <div className={cl.placeholder}>Выберите из списка</div>
                    )}
                </div>
            );
        }
        return null;
    };

    return (
        <SlideBlock
            className={[cl.wrapper, className].join(' ')}
            titleContent={getTitle()}
            elemRefHeightTrigger={ref}
            ref={dragDropRef as React.Ref<HTMLDivElement>}
            style={{opacity: isDragging ? 0 : 1}}
            defaultOpen
        >
            <div ref={ref}>
                {(titleKey === 'func_groups' || titleKey === 'subjects') ? renderSelectedItems() : renderTreeSelect()}
            </div>
        </SlideBlock>
    );
};
//     return (
//         <SlideBlock
//             className={[cl.wrapper, className].join(' ')}
//             titleContent={getTitle()}
//             elemRefHeightTrigger={ref}
//             ref={dragDropRef as React.Ref<HTMLDivElement>}
//             style={{ opacity: isDragging ? 0 : 1 }}
//             defaultOpen
//         >
//             <div ref={ref}>
//                 <TreeSelect
//                     showSearch
//                     style={{ width: '100%' }}
//                     defaultValue={keys}
//                     dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
//                     placeholder="Выберите из списка"
//                     allowClear
//                     treeCheckable
//                     value={keys}
//                     showCheckedStrategy={TreeSelect.SHOW_PARENT}
//                     onChange={value => handleChangeAll(titleKey, value)}
//                     filterTreeNode={(input, option) => {
//                         const text = option?.title?.toString().trim().split(' ');
//                         if(text?.length && /\d+\./.test(text[0])) {
//                             return text.filter((_, i) => !!i).
//                                 join(' ').toLowerCase().startsWith(input.toLowerCase()) ||
//                                 text.join(' ').toLowerCase().startsWith(input.toLowerCase());
//                         }

//                         return typeof option?.title === 'string' &&
//                             option?.title?.toLowerCase().startsWith(input.toLowerCase());
//                     }}
//                     treeData={correctOptions}
//                 />
//             </div>
//         </SlideBlock>
//     );
// };

export default Filter;

