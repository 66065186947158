import React, { FC, useEffect } from 'react';
import cl from './style.module.css';
import { List } from 'antd';
import Text from 'shared/ui/Text';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from "shared/ui/Spinner";
import { useGetPolicyReportsQuery } from 'localentities/policy/model/endpoints';

const PolicyGuide: FC = () => {
    const { data: reports, isLoading: isLoadingReports } = useGetPolicyReportsQuery();
    const navigate = useNavigate(); // Хук для навигации

    useEffect(() => {
        if (reports && reports.length === 1) {
            // Если только один отчет, перенаправляем на его детальную страницу
            navigate(`/policy-guide/${reports[0].id}`);
        }
    }, [reports, navigate]);

    return (
        <div className={cl.wrapper}>
            {isLoadingReports
                ?   <Spinner size="m" />
                :   <List
                        className={cl.list}
                        size="large"
                        header={<Text variant="m" color="primary" bold>Список poll отчетов</Text>}
                        bordered
                        dataSource={reports}
                        renderItem={(item) =>
                            <List.Item>
                                <Link className={cl.link} to={`/policy-guide/${item.id}`}>
                                    <Text variant="s" color="primary">{item.report_name}</Text>
                                </Link>
                            </List.Item>
                        }
                    />
            }
        </div>
    );
};

export default PolicyGuide;
