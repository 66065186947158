import React, { FC, useState } from 'react';
import cl from './style.module.css';
import Sidebar from './commponents/SideBar';
import { useGetArticlesQuery } from "shared/store/api";
import { Article } from 'localentities/filter/types';
import Text from 'shared/ui/Text';
import { Helmet } from 'react-helmet-async';

const Reference: FC = () => {
    const { data: articles, isLoading } = useGetArticlesQuery();
    const [selectedArticle, setSelectedArticle] = useState<Article | null>(null);

    const selectedArticleId = selectedArticle?.id ?? null;

    const addIDsToHeadings = (htmlContent: string) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const headings = doc.querySelectorAll('h2, h3, h4, h5, h6');
        headings.forEach((heading, index) => {
            heading.id = `heading-${index}`;
        });
        return doc.body.innerHTML;
    };

    const convertOEmbedToIframe = (htmlContent: string) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const oembeds = doc.querySelectorAll('oembed');

        oembeds.forEach(oembed => {
            const src = oembed.getAttribute('url');
            console.log(`Found oembed with URL: ${src}`);

            if (src && oembed.parentNode) {
                const youtubeMatch = src.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/);
                let embedSrc;

                if (youtubeMatch) {
                    const videoId = youtubeMatch[1];
                    embedSrc = `https://www.youtube.com/embed/${videoId}`;
                } else {
                    embedSrc = src;
                }

                const iframe = document.createElement('iframe');
                iframe.setAttribute('src', embedSrc);
                iframe.style.width = "100%";
                iframe.style.height = "400px";
                iframe.frameBorder = "0";
                iframe.allowFullscreen = true;
                oembed.parentNode.replaceChild(iframe, oembed);
            } else {
                console.warn(`Skipping oembed because src is ${src} and parentNode is ${oembed.parentNode}`);
            }
        });

        return doc.body.innerHTML;
    };

    const handleSelectArticle = (articleId: number) => {
        if (articles) {
            const article = articles.find(a => a.id === articleId);
            if (article) {
                let processedContent = addIDsToHeadings(article.content);
                processedContent = convertOEmbedToIframe(processedContent);
                setSelectedArticle({ ...article, content: processedContent });
            }
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className={cl.wrapper}>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className={cl.container}>
                <div className={cl.rowFiltr}>
                    <Sidebar articles={articles || []} onSelectArticle={handleSelectArticle} selectedArticleId={selectedArticleId} />
                </div>
                <div className={cl.rowContent}>
                    <div className={cl.contentWrapper}>
                        {selectedArticle ? (
                            <div>
                                <Text variant="header" color="brand-1">{selectedArticle.title}</Text>
                                <div dangerouslySetInnerHTML={{ __html: selectedArticle.content }} style={{
                                    maxWidth: '600px',
                                    margin: '0 auto'
                                }} />
                            </div>
                        ) : (
                            <Text variant="small" color="gray-8">Выберите статью из списка слева</Text>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Reference;
